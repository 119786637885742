<template>
    <div class="tw-flex tw-items-center">
        <div class="hover:tw-bg-k-true-blue-100 tw-flex tw-items-center tw-p-1 tw-rounded-full">
            <input :id="label" name="name" type="radio" :class="inputClass" :disabled="disabled" :value="label"
                   @change="onRadioSelectionChange($event)">
        </div>
        <div class="tw-ml-2 tw-text-xs">
            <label :for="label" :class="labelClass">{{ label }}</label>
            <label v-if="valid == false" class="tw-text-red-500 tw-text-xs tw-w-full">{{ errorMsg }}</label>
        </div>
    </div>
</template>

<script>
import {computed, defineComponent, ref} from '@vue/composition-api'

export default defineComponent({
    name: "PRadioButton",
    props: {
        label: {
            type: String,
            default: 'Home'
        },
        widthOptions: {
            type: String,
            validator: function (value) {
                return ['1/3', '2/3', '1/4', '2/4', '3/4', 'full'].indexOf(value) !== -1
            },
            default: "2/4"
        },
        valid: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errorMsg: {
            type: String,
            default: "Error message"
        },
        checked: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onRadioSelectionChange(event) {
            this.$emit('radio-selection-changed', event.target.value)
        }
    },
    setup(props) {
        const isValid = ref(props.valid);
        const isDisabled = ref(props.disabled);
        const validInput = function () {
            return isValid.value == false ? 'tw-border-k-red-500' : ''
        };
        const labelTextColor = function () {
            if (isValid.value == false) {
                return 'tw-text-red-500'
            } else if (isDisabled.value == true) {
                return 'tw-text-k-gray-500 tw-cursor-not-allowed'
            } else {
                return 'tw-text-k-gray-800'
            }
        };
        const inputDisabled = function () {
            return isDisabled.value == true ? 'tw-cursor-not-allowed' : ''
        };
        const labelClass = computed(() => (
                ['tw-text-xs',
                    'tw-cursor-pointer',
                    labelTextColor(),
                    inputDisabled(),
                    validInput()
                ]
        ));
        const inputClass = computed(() => (
                ['tw-appearance-none',
                    'tw-bg-white',
                    'tw-m-0',
                    'tw-w-4',
                    'tw-h-4',
                    'tw-rounded-full',
                    'tw-border-solid',
                    'tw-border',
                    'tw-border-k-gray-500',
                    'tw-place-content-center',
                    'checked:tw-border',
                    'checked:tw-border-4',
                    'checked:tw-border-white',
                    'checked:tw-bg-k-true-blue',
                    'checked:tw-ring-k-true-blue',
                    'checked:tw-ring-2',
                    inputDisabled(),
                    validInput()
                ]
        ));

        return {labelClass, inputClass}

    }
})
</script>