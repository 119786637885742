<template>
    <div v-if="isEMROrigin">
        <div v-show="showMessageEnvelopeUnreadCount" class="tw-shadow-none tw-cursor-pointer">
            <div @click="redirectToMessageCenter">
          <span class="material-icons">
            mail_outline
            <div class="message-circle tw-rounded-full tw-h-2 tw-w-2 tw-items-center tw-justify-center tw-bg-red-600"/>
          </span>
                <span id="messages-count" class="tw-not-italic">
            {{ unreadCount }}
          </span>
            </div>
        </div>
        <div v-show="!showMessageEnvelopeUnreadCount" class="tw-shadow-none tw-cursor-pointer">
            <div @click="redirectToMessageCenter">
                <span class="material-icons">mail_outline</span>
            </div>
        </div>
    </div>
    <div v-else class="tw-flex tw-relative tw--mt-1 tw-mr-5">
        <v-btn href="/portal/messages"
               icon
               color="purple">
            <v-icon>mdi-email-outline</v-icon>
        </v-btn>
        <div data-qa-id="portal-messages-count"
             :class="['tw-absolute tw-rounded-full tw-text-white tw-bg-k-green-300 tw-h-6 tw-z-index-2 tw-ml-7 tw-text-sm tw-font-bold', autoWidth]"
             v-if="unreadCount && unreadCount > 0">
            <p class="tw-text-center">{{ unreadCount }}</p>
        </div>
    </div>
</template>

<script>
import {Subscriber} from "../../lib/websockets/subscriber"
import axios from '~shared/Axios'

export default {
    name: "MessageEnvelopeWrapper",
    props: {
        currentUserParticipantType: {
            type: String,
            required: true,
        },
        currentUserId: {
            type: Number,
            required: true,
        },
        websocketUrl: {
            type: String,
            required: true,
        },
        instanceId: {
            type: String,
            required: true,
        },
        authToken: {
            type: String
        },
        isEMROrigin: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {},
    created() {
        this.countUnread()

        if (this.websocketUrl) {
            this.websocket = new Subscriber({
                websocketUrl: this.websocketUrl,
                instanceId: this.instanceId,
                namespace: "Messaging",
                topic: this.topic,
                dataHandler: this.dataHandler
            })
        }
    },
    destroyed() {
        this.closeWebsocket()
    },
    mounted() {
        if (localStorage.getItem('previousMessageCount')) {
            this.previousCount = localStorage.getItem('previousMessageCount');
        }
    },
    data() {
        return {
            unreadCount: 0,
            showInside: false,
            previousCount: 0,
            websocket: null
        }
    },
    computed: {
        showMessageEnvelopeUnreadCount() {
            return this.unreadCount > 0;
        },
        topic() {
            return `messaging/messages/${this.currentUserParticipantType}/${this.currentUserId}`;
        },
        autoWidth() {
            return (this.unreadCount && this.unreadCount > 99) ? 'tw-w-auto' : 'tw-w-6'
        }
    },
    watch: {
        unreadCount: {
            handler() {
                if (this.previousCount > this.unreadCount) {
                    this.persistMessageCount()
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        dataHandler({filter}) {
            const {topic} = filter
            if (topic === this.topic) {
                this.countUnread()
            }
        },
        countUnread() {
            axios.get(`${!this.isEMROrigin ? "/portal" : ""}/messaging/messages/count_unread`, {params: {archived: false}})
                    .then(({data: {count}}) => {
                        this.unreadCount = count
                    })
        },
        persistMessageCount() {
            this.previousCount = this.unreadCount
            localStorage.setItem('previousMessageCount', this.previousCount);
        },
        show() {
            this.showInside = !this.showInside
            this.persistMessageCount()
        },
        hide() {
            this.showInside = false
        },
        closeEvent() {
            this.hide()
        },
        closeWebsocket() {
            try {
                if (this.websocket) this.websocket.close()
            } catch (err) {
                console.error(err)
            }
        },
        redirectToMessageCenter() {
          window.location = `${this.isEMROrigin ? '' : 'portal'}/messaging/conversations`
        },
    },
}
</script>

<style scoped>
.message-badge {
  position: relative;
  top: -0.1rem !important;
}

#message-count {
    vertical-align: middle;
}

.message-circle {
    position: absolute;
    bottom: 10px;
    left: 14px;
}
</style>
