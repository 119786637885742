<template>
    <k-alert :notification-text="this.$store.state.alertMessage" :status="this.$store.state.alertStatus"
             @onDismiss="onDismissHandler" v-if="this.$store.state.hasAlert"
             class="tw-absolute tw-z-20 tw-py-4 tw-mt-8" :auto-dismiss="true"
             style="left: 4%; right: 4%"
             :has-success-icon="true">
    </k-alert>
</template>

<script>
import KAlert from "components/shared/alerts/KAlert";

export default {
    name: "KAlertWrapper",
    components: {KAlert},
    methods: {
        onDismissHandler() {
            this.$store.state.hasAlert = false
        }
    }
}
</script>