<template>
    <div :class="[containerBorderStyle, validInput] ">
        <ul class="tw-overflow-auto tw-list-none tw-max-w-4xl tw-max-h-32 tw-pl-1 tw-m-0"
            v-if="chipListItems && chipListItems.length >0" ref="selectedRecipients">
            <li v-for="(item, index) in chipListItems" :key="index" class="tw-float-left tw-ml-1 tw-pb-0">
                <div :class="['tw-flex tw-w-fit tw-p-1 tw-pl-3', chipBorderStyle]">
                  {{ item.name.full }}
                    <button class="tw-cursor-pointer tw-ml-auto tw-px-2" @click="onClick(item)">
                        <KIcon icon="close"></KIcon>
                    </button>
                </div>
            </li>
        </ul>
        <slot name="body"></slot>
    </div>
</template>
<script>
import {KIcon} from '@kipusystems/kipu-ui'

export default {
    name: 'PChipList',
    components: {KIcon},
    props: {
        listItems: {},
        valid: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        validInput() {
            return this.valid ? 'tw-border-k-gray-500' : 'tw-border-k-red-500'
        },
    },
    watch: {
        valid(newValue, oldValue) {
            this.validInput()
        },
        listItems(newValue, oldValue) {
            this.chipListItems = newValue
        },
    },
    data() {
        return {
            containerBorderStyle: 'tw-border tw-border-solid tw-rounded',
            chipBorderStyle: 'tw-border tw-border-solid tw-border-k-gray-500 tw-rounded-full tw-mt-2',
            chipListItems: this.listItems
        }
    },
    updated() {
        // Call function to keep Recipient(s) box scroll-bar at bottom
        if(this.$refs.selectedRecipients)
            this.$nextTick(() => this.scrollToEnd());
    },
    methods: {
        onClick(item) {
            this.$emit('remove-chip', item);
        },
        scrollToEnd: function () {
            var content = this.$refs.selectedRecipients;
            content.scrollTop = content.scrollHeight;
        }
    },
}
</script>