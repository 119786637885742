<template>
  <div class="list-container">
    <div class="list-item" v-for="(item, index) in items" :key="index">
      <div class="card sheet tw-mx-auto card-info tw-group"
           :class="backgroundStyle(item)"
           width="300"
      >
        <span class="tw-mr-1 legacy-severity"
              v-show="item.is_legacy"
              :class="showLegacyIcons(item.severity)"
        />
        <div class="severity-indicator"
             :class="[item.severity ? item.severity.toLowerCase() : '']"
             v-show="!item.is_legacy"
        />
        <div>
          <div v-if="item.day_type.category !== 'Empty'"
               class="card-actions tw-flex tw--ml-7 tw--mt-4"
          >
            <small class="tw-flex-none tw-w-30 tw-not-italic tw-text-sm tw-text-gray-600">
              {{ formatDateByLocale(item) }}
            </small>
            <div class="spacer tw-flex-grow"></div>
            <button class="tw-flex-none tw-p-0 tw-w-1 tw--mr-8 tw-cursor-pointer"
                    @click="markNotificationAsRead(item.id, item.is_legacy)"
            >
              <span class="glyphicon glyphicon-remove-sign tw-text-transparent group-hover:tw-text-gray-500" />
            </button>
          </div>

          <div class="card-text tw--ml-5 tw--mt-2">
            <div class="tw-text-base tw-not-italic tw-font-bold"
                 :class="titleStyle(item)"
                 @click="redirectToInbox(item)"
            >
              {{ item.title }}
            </div>

            <div class="tw-not-italic tw-text-sm tw-text-gray-900 tw-mt-1"
                 v-bind:style="{fontSize: 0.910+'rem'}"
                 v-if="item.event_message !== undefined || item.event_message !== ''"
            >
              {{ item.event_message }}
            </div>

            <div class="tw-not-italic tw-text-sm tw-text-gray-900 tw-mt-1"
                 v-bind:style="{fontSize: 0.910+'rem'}"
                 v-if="prepareItemBodyForDisplay(item) !== ''"
            >
              {{ prepareItemBodyForDisplay(item) | truncate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '~shared/Axios'
import moment from 'moment-timezone'

export default {
  name: "NotificationCard",
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    authToken: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      required: true
    }
  },
  data(){
    return {
      unreadCount: null,
      hover: false,
    }
  },
  methods: {
    prepareItemBodyForDisplay(item) {
      return item.title === 'Reports: Results Ready' ? '' : item.body
    },
    formatDateByLocale(item) {
      moment.locale(this.locale === "en_GB" ? "en-gb" : "en")
      let date = item.created_at
      if (item.day_type.category === "Today") {
        let time = moment(date).tz(timeZoneLong).format('LT')

        return `${item.day_type.category}, ${time.toLowerCase()}`
      } else {
        return moment(date).tz(timeZoneLong).format('L')
      }
    },
    async markNotificationAsRead(id, is_legacy) {
      try {
        const url = is_legacy === true ? "/event_rules/notifications/legacy_read" : "/event_rules/notifications/read"
        await axios.patch(url, {
          ids: [id]
        }, {
          headers: {
            'Content-Type': 'application/json',
            "X-CSRF-TOKEN": this.authToken
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    redirectToInbox(item){
      let legacy_param = item.is_legacy ? '&is_legacy_view=true' : ''
      if (item.id !== undefined) {
        window.location = `/user_notifications/inbox/view?notification_id=${item.id}${legacy_param}`
      }
    },
    backgroundStyle(item) {
      if (item.day_type.category === "Today") {
        return 'tw-shadow-md tw-bg-white'
      } else if (item.day_type.category === 'Empty') {
        return 'tw-shadow-none tw-bg-white'
      } else {
        return 'tw-shadow-none tw-bg-gray-100 hover:tw-shadow-md hover:tw-bg-white'
      }
    },
    titleStyle(item) {
      if(item.day_type.category === 'Empty') {
        return 'tw-text-gray-400'
      } else {
        return 'group-hover:tw-text-blue-500 tw-cursor-pointer'
      }
    },
    showLegacyIcons(severity) {
      let severityLevel = severity ? severity.toLowerCase() : ''
      if (severityLevel === 'ok') {
        return 'glyphicon glyphicon-ok-sign tw-text-green-700'
      } else if (severityLevel === 'error') {
        return 'glyphicon glyphicon-exclamation-sign tw-text-red-700'
      }
    },
  }
}
</script>

<style scoped>
  button, button:hover {
    background-color: transparent;
    border: solid 1px transparent;
  }
  button:focus{
    outline:none !important;
  }
  .error {
    color: inherit !important;
  }
  .legacy-severity {
    font-size: 1.15rem;
    position: absolute;
    left: -40px !important;
    top: -20px !important;
  }
  .severity-indicator {
    border-radius: 1rem;
    width: .35rem;
    padding: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
  }
  .severity-indicator.info {
    background-color: #5bc0de !important;
  }
  .severity-indicator.warn {
    background-color: #e99002 !important;
  }
  .severity-indicator.crit {
    background-color: #f04124 !important;
  }
  .card-info {
    text-align: left;
    padding: 1px;
    border: solid 5px transparent;
    margin-bottom: 5px;
  }
  .sheet {
    border-radius: 0;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
  }
  .card.sheet {
    padding: 20px 40px;
  }
  .card {
    display: block;
    width: 310px;
    min-height: 110px;
    max-height: 150px;
    outline: none;
    text-decoration: none;
    transition-property: box-shadow, opacity;
    overflow-wrap: break-word;
    position: relative;
    white-space: normal;
  }
  .card {
    border-radius: 0px 8px 8px 0px;
  }
  .card-actions {
    align-items: center;
    display: flex;
    padding: 8px;
    color: lightslategray;
  }
</style>
