<template>
    <div>
        <div class="tw-flex tw-gap-8">
            <p v-if="radioGroupLabel"
               class="tw-text-k-gray-700"
            >
                {{ radioGroupLabel }} <span class="tw-text-red-600"> * </span>
            </p>
            <div v-for="item in groupList" :key="item.name">
                <PRadioButton :label="item.name" :checked="item.selected"
                              @radio-selection-changed="onRadioSelectionChange(item)"></PRadioButton>
            </div>
            <InfoToolTip :tooltip-label="tooltipLabel"/>
        </div>
        <label v-if="!valid"
               class="tw-text-red-500 tw-text-xs tw-w-full">{{ errorMsg }}</label>
    </div>
</template>
<script>
import PRadioButton from "components/portal/messages/PRadioButton";
import InfoToolTip from "components/admin/portal/settings/form/InfoToolTip";

export default {
    name: 'PRadioButtonGroup',
    components: {InfoToolTip, PRadioButton},
    props: {
        tooltipLabel: {
            type: String,
            required: false,
            default: "A message type must be selected to move forward"
        },
        radioGroupLabel: {
            type: String,
            required: false,
            default: ""
        },
        groupList: {
            type: Array,
            required: true,
            default: () => []
        },
        errorMsg: {
            type: String,
            default: "Error"
        },
        valid: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            value: {}
        }
    },
    methods: {
        onRadioSelectionChange(selectedItem) {
            this.value = selectedItem
            this.$emit('radio-group-selection-changed', selectedItem)
        }
    }
}
</script>