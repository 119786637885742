import axios from '~shared/Axios'
import Vuex from "vuex";
import Vue from "vue";
import mainNotificationStore from "store/notifications";

Vue.use(Vuex);

const state = {
    authToken: {},
    bufferValue: 0,
    messageRegardingList: [],
    selectedRegarding: {},
    messageRecipientsList: [],
    selectedRecipient: {},
    messageData: {},
    mostRecentMessages: [],
    selectedConversationId: "",
    selectedConversation: null,
    isRequestComplete: false
};

const GET_RECIPIENTS_LIST_PATH = "/messaging/conversation_participants/get_selectable"
const GET_MOST_RECENT_MESSAGES_PER_CONVERSATION_PATH = "/messaging/messages/list_most_recent_per_conversation"
const CONVERSATION_BASE_PATH = "/messaging/conversations"

const config = {
    headers: {
        "Content-Type": "application/json",
    },
    onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
        );
        state.bufferValue = percentCompleted;
    },
    onDownloadProgress: (progressEvent) => {
    let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
    );

        state.bufferValue = percentCompleted;
},
};

const actions = {
    resetSelectedConversation({commit}) {
        state.selectedConversationId = null
        state.selectedConversation   = null
    },
    setUser({commit}, data) {
        commit("SET_USER", data.user);
        return new Promise((resolve, reject) => {
            resolve("User set");
        });
    },
    async composeMessage({commit}, params = {isArchived: false}) {
        state.isRequestComplete = false
        try {
            await axios.post(
              CONVERSATION_BASE_PATH,
              state.messageData,
              config
            )

            await mainNotificationStore.dispatch('showNotification', {
                message: "Sent!",
                status: 'success'
            })
            this.dispatch("getMostRecentMessagesPerConversation", params)
            state.isRequestComplete = true
        } catch (error) {
            console.error(error);
            await mainNotificationStore.dispatch('showNotification', {
                message: "The message was not sent. Please try again.",
                status: 'error'
            });
            state.isRequestComplete = true
        }
    },
    getMessageRecipientsList({commit}, params = null) {
        state.isRequestComplete = false
        let query = Object.assign({}, config);

        let messageType = null;
        let reference_id = null;
        let reference_type = null;

        if(params) {
            messageType = params.messageType;
            reference_id =  params.referenceId;
            reference_type =  params.referenceType;
        }

        query.params = {
            "X-CSRF-TOKEN": state.authToken,
            message_type: messageType || 'staff',
            reference_id : reference_id,
            reference_type: reference_type,
            per_page: 10000
        };
        axios
            .get(GET_RECIPIENTS_LIST_PATH, query)
            .then(
                (response) => {
                    const selectableParticipants = formatData(response.data.selectable_participants)
                    commit("SET_RECIPIENTS_LIST", selectableParticipants);
                    state.isRequestComplete = true
                },
                (error) => {
                    console.log(error);
                    state.isRequestComplete = true
                }
            )
            .catch(function (error) {
                console.log(error);
                state.isRequestComplete = true
            });
    },
    getMostRecentMessagesPerConversation({commit}, params) {
        state.isRequestComplete = false
        state.selectedConversation = null
        state.selectedConversationId = null
        let query = Object.assign({}, config);
        query.params = {
            "X-CSRF-TOKEN": state.authToken,
            ...(params.hasOwnProperty('isArchived')) && {archived: params.isArchived},
            ...(params.search_term && params.search_term != "") && {search_term: params.search_term}
        };
        axios
            .get(GET_MOST_RECENT_MESSAGES_PER_CONVERSATION_PATH, query)
            .then(
                (response) => {
                    state.mostRecentMessages = response.data
                    state.isRequestComplete = true
                    if(state.mostRecentMessages && state.mostRecentMessages.messages && state.mostRecentMessages.messages.length > 0 && (params.hasOwnProperty('mobileView') && !params.mobileView )){
                        state.selectedConversationId = state.mostRecentMessages.messages[0].conversation.id
                        this.dispatch("showConversation")
                    }
                },
                (error) => {
                    console.log(error);
                    state.isRequestComplete = true
                }
            )
            .catch(function (error) {
                console.log(error);
                state.isRequestComplete = true
            });
    },
    showConversation({commit}) {
        state.isRequestComplete = false
        let query = Object.assign({}, config);
        query.params = {
            "X-CSRF-TOKEN": state.authToken,
            current_page: 1
    };
        axios
            .get(`${CONVERSATION_BASE_PATH}/${state.selectedConversationId}`, query)
            .then(
                ({ data }) => {
                    state.selectedConversationId = data.conversation.id
                    state.selectedConversation   = data
                    state.isRequestComplete      = true
                },
                (error) => {
                    console.log(error);
                    state.isRequestComplete = true
                }
            )
            .catch(function (error) {
                console.log(error);
                state.isRequestComplete = true
            });
    },
    async reply({commit}, messageContent) {
        state.isRequestComplete = false
        let query = Object.assign({}, config);
        query.params = {
            "X-CSRF-TOKEN": state.authToken,
        };
        try {
            await axios.post(
              `${CONVERSATION_BASE_PATH}/${state.selectedConversation.conversation.id}/reply`,
              {message_content: messageContent},
              config
            )
            mainNotificationStore.dispatch("showNotification", {
                message: "Sent!",
                status: "success"
            })
            this.dispatch("showConversation")
            state.isRequestComplete = true
        } catch (error) {
            console.error(error);
            mainNotificationStore.dispatch("showNotification", {
                message: "The message was not sent. Please try again.",
                status: "error"
            })
            state.isRequestComplete = true
        }
    },
    async archiveConversations({commit}, conversationIds) {
        state.isRequestComplete = false
        let query = Object.assign({}, config);
        query.params = {
            "X-CSRF-TOKEN": state.authToken,
        };
        try {
            await axios.patch(
              `${CONVERSATION_BASE_PATH}/archive`,
              {ids: conversationIds},
              config
            )

            mainNotificationStore.dispatch("showNotification", {
                message: "Conversations Archived",
                status: "success"
            })
            state.selectedConversationId = null
            state.selectedConversation = null
            this.dispatch("getMostRecentMessagesPerConversation", {isArchived: false})
            state.isRequestComplete = true
        } catch (error) {
            console.error(error);
            mainNotificationStore.dispatch("showNotification", {
                message: "The conversations were not archived. Please try again.",
                status: "error"
            })
            state.isRequestComplete = true
        }
    },
    async unArchiveConversations({commit}, conversationIds) {
        state.isRequestComplete = false
        let query = Object.assign({}, config);
        query.params = {
            "X-CSRF-TOKEN": state.authToken,
        };

        try {
            await axios.patch(
              `${CONVERSATION_BASE_PATH}/restore`,
              {ids: conversationIds},
              config
            )

            mainNotificationStore.dispatch("showNotification", {
                message: "Conversations Unarchived",
                status: "success"
            })
            state.selectedConversationId = null
            state.selectedConversation = null
            this.dispatch("getMostRecentMessagesPerConversation", {isArchived: true})
            state.isRequestComplete = true
        } catch (error) {
            console.error(error);
            mainNotificationStore.dispatch("showNotification", {
                message: "The conversations were not restored. Please try again.",
                status: "error"
            })
            state.isRequestComplete = true
        }
    }
};

const mutations = {
    SET_REGARDING_LIST(state, messageRegardingList) {
        state.messageRegardingList = messageRegardingList;
    },
    SET_RECIPIENTS_LIST(state, messageRecipientsList) {
        state.messageRecipientsList = messageRecipientsList;
    }
};

const formatName = (item) => {
    let fullname = item.name.full ? item.name.full : item.name
    fullname += item.metadata.admission_date ? " " + new Date(item.metadata.admission_date).toLocaleDateString() : ""
    fullname += item.metadata.discharge_date ? " - " + new Date(item.metadata.discharge_date).toLocaleDateString() : item.metadata.admission_date ? " -  Present" : ""
    return fullname
}

const formatData = (list) => {
    list.forEach(item => {
        item.fullname = formatName(item)
    })
    return list
}

export default new Vuex.Store({
    state,
    actions,
    mutations,
});
