import Vue from "vue"

function truncateFilter(value) {
  const size = 33
  if (value) {
    const cleanedText = value.replace(/(<([^>]+)>)/gi, "")

    return (cleanedText.length > size)
      ? `${cleanedText.substring(0, size)}...`
      : cleanedText
  }
}

Vue.filter('truncate', truncateFilter);
