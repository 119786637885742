<template>
  <div ref="dropdownWrapper" class="tw-relative">
    <button class="tw-inline-flex tw-items-center tw-text-2xl tw-rounded-lg tw-border-none tw-py-2.5 tw-px-5 tw-mt-0.5 tw-text-gray-700 tw-bg-gray-200 select_submit_dispensed_options"
       @click="toggleDropdown"
       data-toggle="dropdown"
       aria-haspopup="true"
       aria-expanded="false"
       data-selection=""
    >
      <i v-if="!isDropdownOpen" class="fa fa-caret-down"></i>
      <i v-else class="fa fa-caret-up"></i>
    </button>
    <ul v-if="isDropdownOpen" class="dropdown-menu dropdown-menu-right tw-absolute tw-mt-2 tw-bg-white tw-border tw-shadow-lg tw-text-base" role="menu">
      <li class="no_bullet tw-p-0">
        <a @click="selectOption('add_notes_and_submit')" data-select="add_notes_and_submit">Add Notes and Submit</a>
      </li>
    </ul>

    <add_notes_and_submit_modal
        :show-dialog="showDialog"
        :header-title="modalTitle()"
        @close-modal="handleModalClose"
    />
  </div>
</template>

<script>
export default {
  name: "HandleDispensedDoseChanges",
  components: {
    add_notes_and_submit_modal: () => import('components/mat/doses/AddNotesAndSubmitModal'),
  },
  props: {
    showDispensedOptions: {
      type: Boolean,
      default: false,
      required: true
    },
    headerTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isDropdownOpen: this.showDispensedOptions,
      showDialog: false,
      modalTitle() {
        return `Add ${this.headerTitle}`;
      },
    }
  },
  watch: {},
  computed: {},
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectOption(option) {
      if (option === "add_notes_and_submit") {
        this.showDialog = true; // Open modal
      } else {
        console.log("Handle selection logic here: ", option); // Handle selection logic here
      }
    },
    handleModalClose() {
      this.isDropdownOpen = false;
      this.showDialog = false; // Update showDialog when modal is closed
    },
    handleClickOutside(event) {
      if (this.$refs.dropdownWrapper && !this.$refs.dropdownWrapper.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    },
  }
}
</script>
