import axiosUpstream from 'axios'
import { csrfToken } from './CSRFTokens'

export const axios = window.webpackAxios || axiosUpstream

if (!window.webpackAxios) {
  window.webpackAxios = axios
  window.axios = axios
  pageLoaded(() => {
    axios.interceptors.request.use((config) => {
      config.headers['X-CSRF-TOKEN'] = csrfToken()
      return config
    })
  })
}

export default axios
