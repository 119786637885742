// this is a mounter
import Vue from 'vue'
import NotificationBellWrapper from '@/components/event_rules/notifications/BellWrapper'
import '@/lib/vue_extensions/filters/truncate'
import '@/lib/vue_extensions/directives/kipu-click-outside'

function mount_notification_bell(){
  const el = document.getElementById('event-rules-notification-bell')

  if(!el) return

  const data = Object.assign({}, el.dataset)
  const props = JSON.parse(data.props || "{}")

  const app = new Vue({
    render: h => h(NotificationBellWrapper, { props })
  }).$mount()

  el.appendChild(app.$el)
}

packLoader(mount_notification_bell)
