<template>
  <div class="tab"
       :class="this.tabStyle"
       v-show="isActive"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Tab'
      },
      tabStyle: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        isActive: true
      }
    }
  }
</script>

<style scoped>
  .tab {
    display: inline-block;
    color: black;
    padding: 20px;
    min-width: 300px;
    border-radius: 10px;
    min-height: 24px;
    margin-top: 11px;
  }
</style>
