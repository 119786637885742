const sockets = new Object()
const subscribers = new Object()

let unloading = false

const onBeforeUnload = () => {
  for(const key in sockets) {
    sockets[key].socket.close()
    delete sockets[key]
  }
}

const socketID = (options) => {
  const { websocketUrl, instanceId, namespace, topic } = options || {}
  return `${websocketUrl}.${instanceId}.${namespace}.${topic}`
}

function getSocket(options) {
  return sockets[socketID(options)]
}

function deleteSocket(options) {
  delete sockets[socketID(options)]
  if (!Object.keys(sockets).length) {
    window.removeEventListener('beforeunload', onBeforeUnload)
    unloading = false
  }
}

function setSocket(socketHandler) {
  sockets[socketID(socketHandler)] = socketHandler
  if (!unloading) window.addEventListener('beforeunload', onBeforeUnload)
  unloading = true
  return socketHandler
}

function parseJson(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

function genUniqueId() {
  return Math.round(new Date().getTime() + (Math.random() * 100))
}

function createSocketHandler(options) {
  return getSocket(options) || new KipuWebsocket(options)
}

window.KipuWebsocket = window.KipuWebsocket || class KipuWebsocket {
  static init(options) {
    const handler = createSocketHandler(options)
    if (options.dataHandler) handler.addDataHandler(options.dataHandler)
    if (options.onClose) handler.addCloseHandler(options.onClose)
    if (options.onError) handler.addErrorHandler(options.onError)
    return handler.socket
  }

  constructor({ websocketUrl, instanceId, namespace, topic }) {
    this.websocketUrl = websocketUrl
    this.instanceId = instanceId
    this.namespace = namespace
    this.topic = topic
    this.dataHandlers = []
    this.closeHandlers = []
    this.errorHandlers = []

    this.init()
  }

  addDataHandler(handler) {
    if(!this.dataHandlers.includes(handler)) this.dataHandlers.push(handler)
  }

  addCloseHandler(handler) {
    if(!this.closeHandlers.includes(handler)) this.closeHandlers.push(handler)
  }

  addErrorHandler(handler) {
    if(!this.errorHandlers.includes(handler)) this.errorHandlers.push(handler)
  }

  removeDataHandler(handler) {
    if(this.dataHandlers.includes(handler)) this.dataHandlers.splice(this.dataHandlers.indexOf(handler), 1)
  }

  removeErrorHandler(handler) {
    if(this.errorHandlers.includes(handler)) this.errorHandlers.splice(this.errorHandlers.indexOf(handler), 1)
  }

  removeCloseHandler(handler) {
    if(this.closeHandlers.includes(handler)) this.closeHandlers.splice(this.closeHandlers.indexOf(handler), 1)
  }

  init() {
    if(this.socket) return this.socket

    const socket = new WebSocket(this.websocketUrl)
    this.socket = socket

    socket.id = genUniqueId()
    setSocket(this)

    socket.onopen = () => {
      socket.send(JSON.stringify({
        action: 'subscribe',
        data: {
          instance_id: this.instanceId,
          subscriptions: {
            topic: this.namespace,
            event: this.topic
          }
        }
      }))
    }
    socket.onerror = (err) => this.onError(err)
    socket.onmessage = (response) => this.onMessage(response)
    socket.onclose = (ev) => this.onClose(ev)

    return socket
  }

  onMessage({ data }) {
    const parsed = parseJson(data)
    if (parsed){
      this.dataHandlers.forEach((handler) => Promise.resolve(parsed).then(handler))
    }
  }

  async onError(err) {
    try {
      await Promise.all(this.errorHandlers.map((handler) => Promise.resolve(err).then(handler)))
    } catch(handlerErr) {
      console.error(handlerErr)
    }
  }

  async onClose(ev) {
    try {
      await Promise.all(this.closeHandlers.map((handler) => Promise.resolve(ev).then(handler)))
    } catch(err) {
      console.error(err)
    }

    deleteSocket(this)
  }
}

window.KipuSubscription = window.KipuWebsocket

window.KipuWebsocket.Subscriber = window.KipuWebsocket.Subscriber || class KipuWebsocketSubscriber {
  constructor(options) {
    this.key = socketID(options)
    this.close = this.close.bind(this)
    this.socketHandler = createSocketHandler(options)
    this.dataHandler = options.dataHandler
    this.onClose = options.onClose
    this.onError = options.onError

    this.subscriptions[this] = true

    if(options.dataHandler) this.socketHandler.addDataHandler(this.dataHandler)
    if(options.onClose) this.socketHandler.addCloseHandler(this.onClose)
    if(options.onError) this.socketHandler.addErrorHandler(this.onError)
  }

  close() {
    this.socketHandler.removeDataHandler(this.dataHandler)
    this.socketHandler.removeCloseHandler(this.onClose)
    this.socketHandler.removeErrorHandler(this.onError)
    delete this.subscriptions[this]
    if(!Object.keys(subscribers[this.key])) this.socketHandler.socket.close()
  }

  get subscriptions() {
    // Using a hash for O(1) lookup
    if (!subscribers[this.key]) subscribers[this.key] = new Object()
    return subscribers[this.key]
  }

  get socket() {
    return this.socketHandler.socket
  }
}

export const KipuWebsocket = window.KipuWebsocket
export const Socket = KipuWebsocket
export const KipuWebsocketSubscriber = window.KipuWebsocket.Subscriber
export const Subscriber = KipuWebsocketSubscriber
