import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api'
import {KPatientAutoComplete} from "@kipusystems/kipu-ui";

Vue.use(VueCompositionAPI);
const PATIENT_QUICK_SEARCH_SELECTOR= "[data-vue-placeholder][data-vue-class='KPatientAutoComplete']";

packLoader(() => {
  const placeholder = document.querySelector(PATIENT_QUICK_SEARCH_SELECTOR);
  if (!placeholder) return;
  const _metadata = {...placeholder.dataset};
  const _props = JSON.parse(_metadata.vueProps || "{}");
  const _attrs = JSON.parse(_metadata.vueAttrs || "{}");
  // const _slots = JSON.parse(_metadata.vueSlots || {});
  let _vueWrapper = new Vue({
      render: h => h(KPatientAutoComplete, {
          props: _props,
          attrs: _attrs
      })
  }).$mount();
  placeholder.appendChild(_vueWrapper.$el);
});
