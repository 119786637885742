import Vue from 'vue';
import messagingStore from "store/messages";
import notificationStore from "store/notifications";
import KAlertWrapper from "components/shared/alerts/KAlertWrapper";

function mount_alert(){
    const el = document.getElementById('main-alert')
    if(!el) return

    const app = new Vue({
        store: notificationStore,
        render: h => h(KAlertWrapper)
    }).$mount()
    el.appendChild(app.$el)
}

packLoader(mount_alert)
