var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",class:_vm.baseContainerClass},[_c('k-use-auto-complete',{attrs:{"value":_vm.search,"url":_vm.url,"items":_vm.items,"mode":_vm.mode,"modeFilters":_vm.modeFilters,"triggerLength":_vm.triggerLength,"parameterName":_vm.parameterName,"extraParams":_vm.extraParams,"filterOptions":_vm.filterOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var filteredItems = ref.filteredItems;
    var isFetching = ref.isFetching;
    var searchUrl = ref.searchUrl;
    var error = ref.error;
    var fetchedItems = ref.fetchedItems;
    var canTrigger = ref.canTrigger;
return [_c('k-search',{attrs:{"showLabel":_vm.showLabel,"prependIcon":true,"placeholder":_vm.placeholder,"label":_vm.label,"invert":_vm.isInverted},on:{"clicked":function($event){_vm.expandInput()
        _vm.expandResults()},"input":function($event){return _vm.emitSearch($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),(_vm.display)?_c('k-card',{staticClass:"tw-absolute tw-top-12 tw-z-50",attrs:{"widthOption":"full"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"tw-grid tw-place-items-center tw-text-k-gray-800"},[_vm._t("messages",null,null,{ searchUrl: searchUrl, error: error, fetchedItems: fetchedItems, canTrigger: canTrigger }),_vm._v(" "),_c('div',{staticClass:"tw-grid tw-w-full tw-place-items-left"},[(canTrigger && (filteredItems.length > 0))?_vm._t("results-header",null,null,{ filteredItems: filteredItems }):_vm._e(),_vm._v(" "),(isFetching)?_vm._t("loading"):_c('div',[(canTrigger && (filteredItems.length > 0))?_c('ul',_vm._l((filteredItems),function(item,index){return _c('li',{key:index,staticClass:"tw-grid tw-cursor-pointer tw-mb-2 tw-py-1 tw-px-4 tw-rounded hover:tw-bg-k-purple-500"},[_vm._t("item",null,null,{item: item, token: _vm.search})],2)}),0):_vm._e(),_vm._v(" "),(filteredItems.length == 0 && canTrigger)?_vm._t("no-data"):_vm._e()],2)],2)],2)]},proxy:true},{key:"footer",fn:function(){return [(canTrigger && (filteredItems.length >= 1))?_c('div',{staticClass:"tw--mt-px tw-p-2"},[_vm._t("results-footer",null,null,{ filteredItems: filteredItems })],2):_vm._e()]},proxy:true}],null,true)}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }