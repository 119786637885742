// this is a mounter
import Vue from 'vue'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import ToastWrapper from '@/components/event_rules/notifications/ToastWrapper'
import 'vue-snotify/styles/material.css'

const options = {
    toast: {
        position: SnotifyPosition.rightTop
    }
}

Vue.use(Snotify, options)

function mount_toaster(){
    const el = document.getElementById('event-rules-toast');
    if (!el) return;

    const data = {...el.dataset};

    const props = JSON.parse(data.props || {})

    const app = new Vue({
        render: h => h(ToastWrapper, { props })
    }).$mount()

    el.appendChild(app.$el)
}

packLoader(mount_toaster)
