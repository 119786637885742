<template>
  <div :class="baseContainerClass" ref="container" >
    <k-use-auto-complete
      :value="search"
      :url="url"
      :items="items"
      :mode="mode"
      :modeFilters="modeFilters"
      :triggerLength="triggerLength"
      :parameterName="parameterName"
      :extraParams="extraParams"
      :filterOptions="filterOptions"
      v-slot:default="{
        filteredItems,
        isFetching,
        searchUrl,
        error,
        fetchedItems,
        canTrigger,
      }"
    >
      <k-search
        :showLabel="showLabel"
        v-model="search"
        :prependIcon="true"
        :placeholder="placeholder"
        :label="label"
        :invert="isInverted"
        @clicked="
          expandInput()
          expandResults()
        "
        @input="emitSearch($event)"
      />
      <k-card
        class="tw-absolute tw-top-12 tw-z-50"
        widthOption="full"
        v-if="display"
      >
          <template #default>
          <div class="tw-grid tw-place-items-center tw-text-k-gray-800">
            <slot
              name="messages"
              v-bind="{ searchUrl, error, fetchedItems, canTrigger }"
            >
            </slot>
            <div class="tw-grid tw-w-full tw-place-items-left">
              <slot
                v-if="canTrigger && (filteredItems.length > 0)"
                name="results-header"
                v-bind="{ filteredItems }"
              >
              </slot>
              <slot name="loading" v-if="isFetching"> </slot>
              <div v-else>
                <ul v-if="canTrigger && (filteredItems.length > 0)">
                  <li
                    v-for="(item, index) in filteredItems"
                    :key="index"
                    class="
                      tw-grid
                      tw-cursor-pointer
                      tw-mb-2
                      tw-py-1
                      tw-px-4
                      tw-rounded
                      hover:tw-bg-k-purple-500
                    "
                  >
                    <slot name="item" v-bind="{item, token: search}"></slot>
                  </li>
                </ul>
                <slot
                  v-if="filteredItems.length == 0 && canTrigger"
                  name="no-data"
                >
                </slot>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div v-if="canTrigger && (filteredItems.length >= 1)" class="tw--mt-px tw-p-2">
            <slot name="results-footer" v-bind="{ filteredItems }"> </slot>
          </div>
        </template>
      </k-card>
    </k-use-auto-complete>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import { onClickOutside } from '@vueuse/core'


import {KSearch, KCard, KIcon, KUseAutoComplete} from '@kipusystems/kipu-ui'

export default defineComponent({
    name: "PAutoComplete",
    props: {
      url: {
          type: String,
          default: ""
      },
      items:{
          type: Array,
          default() {
              return [];
          }
      },
      triggerLength: {
          type: Number,
          default: 3
      },
      value: {
            type: String,
            default:""
      },
      parameterName:{
          type: String,
          default: "token"
      },
      extraParams:{
          type: Object,
          default(){
              return {
              };
          }
      },
      mode:{
          type: String,
          validator: function(value){
              return ['cached', 'throttled', 'debounced'].indexOf(value) !== -1
          },
          default: "cached",            
      },
      modeFilters:{
        type: Object,
        default(){
            return {
                throttle: 1500,
                debounce: 1000,
                maxWait: 1500
            }
        }            
      },
      filterOptions: {
          type: Object,
          default(){
              //FuseJs options + limit
              return {
                isCaseSensitive: false,
                includeScore: false,
                shouldSort: true,
                includeMatches: false,
                findAllMatches: false,
                minMatchCharLength: 1,
                location: 0,
                threshold: 0.6,
                distance: 100,
                useExtendedSearch: false,
                ignoreLocation: false,
                ignoreFieldNorm: false,
                fieldNormWeight: 1,
              // keys: [
              //     "title",
              //     "author.firstName"
              // ]
              // sortFn: (a,b) =>{
              // },
              // limit: 5
          }
        }
      },
      width:{ type: String, default: 'full' },
      placeholder: { type: String, default: "Search" },
      label: { type: String, default: "Search" },
      showLabel: { type: Boolean, default: false },
      collapsible: { type: Boolean, default: false },
      invert: { type: Boolean, default: false },
      invertState: { type: Boolean, default: false },
      active: { type: Boolean, default: false },
    },
    components: { KSearch, KCard, KUseAutoComplete, KIcon },
    setup(props, context ) {
      // Definitions
      const search = ref(props.value);
      const searchWidth = ref(props.width);
      const inputStart = ref('1');
      const display = ref(false);
      const invert = ref(props.invert);
      const isInverted = ref(props.invertState);
      const activeStatus = ref(props.active);
      const isCollapsible = ref(props.collapsible);
      const container = ref(null);

      // Methods
      const emitSearch = function(event){
        search.value = event
        context.emit('search', event);
        context.emit('input', event);
      }

      const clearSearch = function(){
        search.value = "";
      }

      const expandResults = function(){
        return display.value = true
      };
      const collapseResults = function(){
        return display.value = false
      };
      const toggleInvertState = function(){
        return isInverted.value = !isInverted.value
      };
      const expandInput = function(){
        activeStatus.value = true
        if (invert.value && isInverted.value) {
          toggleInvertState()
        }
        if (isCollapsible.value) {
          inputStart.value = '1'
          return searchWidth.value = '4'
        }
        return true
      }
      const collapseInput = function(){
        collapseResults()
        if (invert.value && activeStatus.value && !isInverted.value) {
          toggleInvertState()
        }
        if (isCollapsible.value){
          inputStart.value = '3'
          return searchWidth.value = '2'
        }
        activeStatus.value = false
        return true
      }

      onMounted(() => {
        isCollapsible.value ? searchWidth.value = '2' :  searchWidth.value = '4'
        isCollapsible.value ? inputStart.value = '3' :  inputStart.value = '1'
      })

      onClickOutside(container, (event) => {
        collapseInput();
        clearSearch();
      })


      // Computed
      const baseContainerClass = computed(() => {
        return [
          'tw-transition',
          'tw-ease-in-out',
          'tw-duration-200',
          'tw-relative',
          `tw-col-span-${searchWidth.value}`,
          `tw-col-start-${inputStart.value}`,
        ]
      })
      // Return
      return { search, searchWidth, display,
               emitSearch, collapseResults, expandResults, expandInput,
               container, baseContainerClass, isCollapsible, isInverted }

    }
})
</script>
