<template>
    <div :class="backgroundStyleClasses">
        <p :class="textStyleClasses">
            <k-icon icon="checkbox-marked-circle-outline" v-if="hasSuccessIcon"></k-icon>
            {{ notificationText }}</p>
        <button :class="buttonStyleClasses" @click="onClick()">
            <k-icon icon="close"></k-icon>
        </button>
    </div>
</template>

<script>
import {computed, defineComponent, ref, onMounted} from '@vue/composition-api'
import {KIcon} from "@kipusystems/kipu-ui";

export default defineComponent({
    name: "KAlert",
    props: {
        hasSuccessIcon: {
            type: Boolean,
            default: false
        },
        notificationText: {
            type: String,
            default: "Success"
        },
        status: {
            type: String,
            validator: function (value) {
                return ['success', 'info', 'warning', 'error'].indexOf(value) !== -1
            },
        },
        autoDismiss: {
            type: Boolean,
            default: false
        },
    },
    emits: ['onDismiss'],
    components: {KIcon},
    methods: {
        onClick() {
            this.$emit('onDismiss');
        },
    },
    setup(props, context) {
        const status = ref(props.status)
        const autoDismiss = ref(props.autoDismiss)
        onMounted(() => {
            if (autoDismiss)
                setTimeout(() => context.emit('onDismiss'), 3000);
        })
        const backgroundStyleClasses = computed(() => ({
            'tw-bg-k-green-300': status.value === 'success',
            'tw-bg-k-tropical-blue-500': status.value === 'info',
            'tw-bg-k-amber-500': status.value === 'warning',
            'tw-bg-k-red-500': status.value === 'error',
            'tw-flex tw-rounded-md tw-items-center': true,
            'tw-w-11/12 tw-content-center': true,
        }));
        const textStyleClasses = computed(() => ({
            'tw-ml-auto tw-break-normal tw-mt-3 lg:tw-truncate tw-text-center lg:tw-text-ellipsis' : true
        }))
        const buttonStyleClasses = computed(() => ({
            'tw-pr-4 tw-ml-auto' : true
        }))
        return {backgroundStyleClasses, textStyleClasses, buttonStyleClasses};
    }
})
</script>
