import Vue from "vue";
import HandleDispensedDoseChanges from '@/components/mat/doses/HandleDispensedDoseChanges';

function mount(){
  const el = document.getElementById('submit-dispensed-options');
  if (!el) return;

  const data = {...el.dataset};

  const props = JSON.parse(data.props || "{}")

  const app = new Vue({
    data() {
      return {
        headerTitle: "" // Initialize headerTitle as an empty string
      };
    },
    mounted() {
      document.addEventListener('click', (event) => {
        if (event.target.matches('.select_dispensed_doses_operation + ul li a')) {
          // Get the text content of the clicked element
          const headerTitle = event.target.textContent.trim();
          // Update the headerTitle directly on the Vue instance
          this.headerTitle = headerTitle;
        }
      });
    },
    render: function(h) {
      return h(HandleDispensedDoseChanges, { props: { ...props, headerTitle: this.headerTitle } });
    }
  }).$mount()
  el.appendChild(app.$el)
}

// Expose the mount function globally
window.mountDispensedDosesOptions = mount;

packLoader(mount)
