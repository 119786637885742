<template>
  <div v-off-click="hideList">
    <p class="tw-absolute tw-text-xs tw--mt-2 tw-ml-2 tw-bg-white tw-px-1 group-focus-within:tw-text-k-purple tw-text-k-gray-900">
      {{ label }}<span v-if="isRequired" class="tw-text-red-600"> * </span></p>
    <PChipList ref="chipList" :list-items="selectedList" :valid="this.isValid" class="tw-pt-2"
               @remove-chip="(selectedItem) => onRemoveChip(selectedItem)">
      <template #body>
        <input ref="searchInput"
               :class="inputClass"
               :disabled="isSelectionDisabled"
               :placeholder=" maxSelection > 1 ? 'Please select one or more' : 'Please select one'"
               @focusin="showList"
               @input="emitSearch($event)"
               @keydown="showList">
      </template>
    </PChipList>
    <ul v-show="isListVisible" :class="ulClass" aria-labelledby="listbox-label" role="listbox" tabindex="-1">
      <template v-for="(item,index) in selectableRecipientsList">
        <li v-if="item.fullname.toLowerCase().includes(search.toLowerCase())" :key="index" :class="liClass"
            @click="updateList(item.name)">
          <div class="tw-flex tw-items-baseline tw-gap-2">
                        <span class="tw-block tw-truncate">
                            {{ item.fullname }}
                         </span>
          </div>
        </li>
      </template>
    </ul>
    <label v-if="this.v$.selectedList.$error"
           class="tw-text-red-500 tw-absolute tw-text-xs">{{ errorMsg }}</label>
  </div>
</template>

<script>
import PChipList from "components/portal/messages/PChipList";
import PRadioButtonGroup from "components/portal/messages/PRadioButtonGroup";
import PRadioButton from "components/portal/messages/PRadioButton";
import useVuelidate from '@vuelidate/core'
import {required} from "@vuelidate/validators";

export default {
  name: "PMultiSelectSearch",
  components: {PRadioButton, PRadioButtonGroup, PChipList},
  setup() {
    return {v$: useVuelidate()}
  },
  directives: {
    offClick: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
        document.body.addEventListener("touchstart", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
        document.body.removeEventListener("touchstart", el.clickOutsideEvent);
      },
      stopProp(event) {
        event.stopPropagation();
      }
    }
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    isSelectionDisabled: {
      type: Boolean,
      required: false,
      default: true
    },
    searchableList: {
      type: Array,
      default: [],
      required: false
    },
    isValid: {type: Boolean, default: true},
    errorMsg: {
      type: String,
      default: "Error message"
    },
    label: {
      type: String,
      default: ""
    },
    maxSelection: {
      type: Number,
      required: false,
      default: 99999
    },
  },
  computed: {
    selectableList() {
      const tempArr = Object.values(this.searchableList)
      tempArr.forEach(element => element.selected = false)
      return tempArr
    },
    inputClass() {
      return ['tw-h-10',
        'tw-bg-white',
        'tw-px-2',
        'tw-py-0',
        'tw-text-base',
        'tw-leading-4',
        'tw-w-full',
        'focus:tw-outline-none',
        'focus:tw-border-2',
        'focus:tw-border-k-purple-500',
        'hover:tw-shadow',
        'tw-rounded',
        this.validInput,
        this.inputDisabledStyle,
      ]
    },
    validInput() {
      return this.isValid == true ? 'tw-border-k-gray-500' : 'tw-border-k-red-500'
    },
    inputDisabledStyle() {
      return this.isSelectionDisabled ? 'tw-cursor-not-allowed tw-bg-k-gray-200' : 'tw-text-black'
    },
    selectableRecipientsList() {
      return this.searchableList
    }
  },
  validations() {
    return {
      selectedList: {required}
    }
  },
  data() {
    return {
      isListVisible: false,
      display: false,
      selectedList: [],
      search: "",
      liClass: ['tw-text-gray-900',
        'tw-cursor-default',
        'tw-select-none',
        'tw-relative',
        'tw-py-2',
        'tw-px-4',
        'tw-m-0',
        'hover:tw-text-purple-500',
      ],
      ulClass: ['tw-absolute',
        'tw-list-none',
        'tw-z-10',
        'tw-mt-1',
        'tw-w-fit',
        'tw-bg-white',
        'tw-shadow-lg',
        'tw-max-h-60',
        'tw-rounded-md',
        'tw-text-base',
        'tw-ring-1',
        'tw-ring-black',
        'tw-ring-opacity-5',
        'tw-overflow-auto',
        'focus:tw-outline-none',
        'sm:tw-text-sm'
      ]
    }
  },
  methods: {
    reset() {
      this.selectedList.forEach(item => {
        this.selectableRecipientsList.splice(0, 0, item);
      })
      // Sorting Recipients array
      this.selectableRecipientsList.sort((a, b) => a.fullname.localeCompare(b.fullname));
      this.selectedList = []
      this.clearSearchInput()
    },
    clearSearchInput() {
      if (this.$refs.searchInput.value)
        this.$refs.searchInput.value = ""
    },
    isInputDisabled() {
      return this.isSelectionDisabled && (this.selectedList && this.selectedList.length >= this.maxSelection)
    },
    hideList() {
      this.isListVisible = false
    },
    showList() {
      this.isListVisible = true
    },
    emitSearch(event) {
      this.search = event.target.value
    },
    onRemoveChip(item) {
      const index = this.selectedList.indexOf(item);
      if (index > -1) {
        this.selectedList.splice(index, 1);
        this.selectableRecipientsList.splice(0, 0, item);
      }
    },
    update(name) {
      this.selectableRecipientsList.forEach(el => {
        if (el.name === name) {
          el.selected = !el.selected
        }
      })
    },
    updateList(name) {
      if (this.isInputDisabled) {
        this.hideList()
      }

      this.selectableList.find((el) => {
        if (el.name === name) {
          const chipIndex = this.selectedList.indexOf(el);
          if (chipIndex === -1) {
            el.selected = true
            this.selectedList.push(el)
            const i = this.selectableRecipientsList.indexOf(el);
            this.selectableRecipientsList.splice(i, 1);
            this.clearSearchInput()
          } else {
            el.selected = false
            this.selectedList.splice(index, 1);
          }
        }
      })
    }
  }
}
</script>
