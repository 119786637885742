<template>
  <div class="notification-alerts">
    <div class="notification-container">
      <div v-show="showNotificationBellUnreadCount" class="tw-shadow-none tw-cursor-pointer">
        <div @click.stop="show">
          <span  class="material-icons">
            notifications
            <div v-show="showNotificationAlert"
                 class="notification-circle tw-rounded-full tw-h-2 tw-w-2 tw-items-center tw-justify-center tw-bg-red-600"
            />
          </span>
            <span id="kmc-downloads-count" class="tw-not-italic">
            {{ unreadCount }}
          </span>
        </div>
      </div>
      <div v-show="!showNotificationBellUnreadCount" class="tw-shadow-none tw-cursor-pointer">
        <div @click.stop="show">
          <span class="material-icons">notifications_none</span>
        </div>
      </div>
      <transition appear>
        <div v-if="showInside"
             v-kipu-click-outside="hide"
        >
          <div class="menu menu-content tw-bg-white">
            <div class="notification-list tw-px-5 tw-shadow-lg">
              <tabs tabsListClass="tw-text-blue-500 tw-text-md tw-not-italic tw-font-bold tw-py-2 tw-ml-1 tw--mr-1"
                    tabsDivClass="tw-bg-white"
                    :tabsListStyle="{fontSize: 14+'px'}"
              >
                <tab :title="`Notifications (${notificationUnreadCount})`"
                     tabStyle="tw--ml-5"
                >
                  <div class="list"
                       v-if="this.todayNotifications.length > 0 || this.yesterdayNotifications.length > 0 || this.olderNotifications.length > 0"
                  >
                    <NotificationListGroup :notifications="todayNotifications"
                                           :authToken="this.authToken"
                                           :category="'Today'"
                                           :locale="locale"
                                           :isLegacy="false"/>
                    <NotificationListGroup :notifications="yesterdayNotifications"
                                           :authToken="this.authToken"
                                           :category="'Yesterday'"
                                           :locale="locale"
                                           :isLegacy="false"/>
                    <NotificationListGroup :notifications="olderNotifications"
                                           :authToken="this.authToken"
                                           :locale="locale"
                                           :category="'Older'"
                                           :isLegacy="false"/>
                  </div>
                  <div class="list"
                       v-else
                  >
                    <NotificationCard :items="this.emptyNotifications" :authToken="''" :locale="locale"/>
                  </div>
                </tab>
                <tab v-if="useKipuMessenger" :title="`Legacy Notifications (${legacyUnreadCount})`"
                     tabStyle="tw--ml-5"
                >
                  <div class="list"
                       v-if="this.legacyTodayNotifications.length > 0 || this.legacyYesterdayNotifications.length > 0 || this.legacyOlderNotifications.length > 0"
                  >
                    <NotificationListGroup :notifications="legacyTodayNotifications"
                                           :authToken="this.authToken"
                                           :category="'Today'"
                                           :locale="locale"
                                           :isLegacy="true"/>
                    <NotificationListGroup :notifications="legacyYesterdayNotifications"
                                           :authToken="this.authToken"
                                           :locale="locale"
                                           :category="'Yesterday'"
                                           :isLegacy="true"/>
                    <NotificationListGroup :notifications="legacyOlderNotifications"
                                           :authToken="this.authToken"
                                           :locale="locale"
                                           :category="'Older'"
                                           :isLegacy="true"/>
                  </div>
                  <div class="list" v-else>
                    <NotificationCard :items="this.emptyNotifications" :authToken="''" :locale="locale"/>
                  </div>
                </tab>
              </tabs>
              <a class="notification-inbox-link tw--mt-3 tw-text-md tw-not-italic tw-font-bold tw-text-gray-900 hover:tw-text-blue-400"
                 href="/user_notifications/inbox/view"
              >
                Go To Inbox
              </a>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import axios from '~shared/Axios'
  import { Subscriber } from "@/lib/websockets/subscriber"
  import Tab from "@/components/shared/Tab";
  import Tabs from "@/components/shared/Tabs";
  import {filteringMixin} from "@/mixins/event_rules/policies/filtering";
  import NotificationListGroup from "./NotificationListGroup";
  import NotificationCard from "./NotificationCard";

  export default {
    name: "NotificationBellWrapper",
    props:{
      currentUserId: {
        type: Number,
        required: true,
      },
      locationId: {
        type: Number,
        default: null
      },
      websocketUrl: {
        type: String,
        required: true,
      },
      instanceId: {
        type: String,
        required: true,
      },
      upperLimit: {
        type: Number,
        default: 85,
      },
      counterLocation: {
        type: String,
        default: "right",
      },
      counterStyle: {
        type: String,
        default: "roundRectangle",
      },
      counterBackgroundColor: {
        type: String,
        default: "#FF0000",
      },
      counterTextColor: {
        type: String,
        default: "#FFFFFF",
      },
      iconColor: {
        type: String,
        default: "#000000",
      },
      authToken: {
        type: String
      },
      locale: {
        type: String,
        required: true
      },
      isLocationEnforced: {
        type: Boolean,
        required: true
      },
      useKipuMessenger: {
        type: Boolean,
        default: false
      }
    },
    components: {
      NotificationCard,
      NotificationListGroup,
      Tab,
      Tabs,
    },
    created() {
      this.pollUpdate();

      if (this.websocketUrl) {
        this.websocket = new Subscriber({
          websocketUrl: this.websocketUrl,
          instanceId: this.instanceId,
          namespace: "EventRules::Notifications",
          topic: this.topic,
          dataHandler: this.dataHandler
        })
      }
    },
    destroyed() {
      this.closeWebsocket()
    },
    mounted() {
      if (localStorage.getItem('previousCount')){
        this.previousCount = localStorage.getItem('previousCount');
      }
    },
    data() {
      return {
        unreadCount: 0,
        legacyUnreadCount: 0,
        notificationUnreadCount: 0,
        todayNotifications: [],
        yesterdayNotifications: [],
        olderNotifications: [],
        legacyTodayNotifications: [],
        legacyYesterdayNotifications: [],
        legacyOlderNotifications: [],
        showInside: false,
        previousCount: 0,
        websocket: null,
        emptyNotifications: [
          {
            title: 'No New Notifications',
            body: 'You are all set...',
            severity: '',
            day_type: {
              date: '',
              category: 'Empty'
            }
          }
        ],
      }
    },
    mixins: [filteringMixin],
    computed: {
      showNotificationBellUnreadCount() {
        return this.unreadCount > 0;
      },
      showNotificationAlert() {
        return this.previousCount < this.unreadCount;
      },
      topic() {
        return `event_rules/notifications/${this.currentUserId}`
      }
    },
    watch: {
      unreadCount: {
        handler(){
          if (this.previousCount > this.unreadCount) {
            this.persistNotificationCount()
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      dataHandler({ filter }) {
        console.log(filter)
        const { location_id: locationId, user_id: userId, bypass_location_scoping: bypassLocationScoping, topic } = filter
        const locationFilter = this.locationFilter(
            {
              filteredLocationId: locationId, locationId: this.locationId, bypassLocationScoping,
              isLocationEnforced: this.isLocationEnforced
            }
        )

        if (topic === this.topic && userId == this.currentUserId && locationFilter) {
          this.pollUpdate();
        }
      },
      pollUpdate() {
        axios.get('/event_rules/notifications/unread')
            .then((response) => {
              this.unreadCount = this.useKipuMessenger ? response.data.count : response.data.notifications.count;
              this.todayNotifications = response.data.notifications.today;
              this.yesterdayNotifications = response.data.notifications.yesterday;
              this.olderNotifications = response.data.notifications.older;
              this.notificationUnreadCount = response.data.notifications.count;
              this.legacyTodayNotifications = response.data.legacy.today;
              this.legacyYesterdayNotifications = response.data.legacy.yesterday;
              this.legacyOlderNotifications = response.data.legacy.older;
              this.legacyUnreadCount = response.data.legacy.count;
            });
      },
      persistNotificationCount() {
        this.previousCount = this.unreadCount
        localStorage.setItem('previousCount', this.previousCount);
      },
      show() {
        this.showInside = !this.showInside
        this.persistNotificationCount()
      },
      hide() {
        this.showInside = false
      },
      closeEvent() {
        this.hide()
      },
      closeWebsocket() {
        try {
          if (this.websocket) this.websocket.close()
        } catch(err) {
          console.error(err)
        }
      },
    },
  }
</script>

<style scoped>
  .notification-badge {
    position: relative;
    top: -0.1rem !important;
    left: 0.6rem !important;
  }
  #notificationBell {
    position: relative !important;
    top: -0.060rem !important;
    right: 0.40rem !important;
  }
  .notification-circle {
    position: absolute;
    bottom: 10px;
    left: 14px;
  }
  .notification-list {
    text-align: center;
    border-radius: 8px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .notification-inbox-link {
    text-align: center;
    padding: 10px;
    margin-top: 15px;
  }

  .v-list, .list {
    min-height: 10px;
    max-height: 500px;
    overflow-x: auto;
  }
  .menu {
    min-width: 24px;
    width: 350px;
    top: 105%;
    right: 0;
    transform-origin: left top;
    z-index: 1;
  }
  .menu-content {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    contain: content;
    will-change: transform;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius: 8px;
  }
</style>
