<template>
    <div :class="baseContainerClass">
        <p v-if="showLabel" :class="labelClass">{{ label }}<span v-if="isRequired" class="tw-text-red-600"> * </span>
        </p>
        <div class="tw-mt-3 tw-relative tw-w-full" v-offClick="closeList">
            <button data-skip-legacy="true" @click="openList" type="button"
                    :class="[buttonClass, validInput, buttonDisabled]" aria-haspopup="listbox" aria-expanded="true"
                    aria-labelledby="listbox-label">
                <span class="tw-truncate">{{ selectedItem }} </span>
                <span :class="caretClass">
          <k-icon v-show="displayList" icon="menu-up"/>
          <k-icon v-show="!displayList" icon="menu-down"/>
        </span>
            </button>
            <ul v-show="displayList" :class="ulClass" tabindex="-1" role="listbox" aria-labelledby="listbox-label">
                <li :class="liClass" v-for="item in itemsList" :key="item.metadata.mr_number"
                    @click="updateList(item)">
          <span class="tw-break-normal"
                :class="[item.selected ? 'tw-font-semibold' : 'tw-font-normal', itemIsEnabled(item)]">
            {{ item.fullname ? item.fullname : item.name }}
                   <div class="tw-col-span-3" v-if="isPatientList">
                <small class="tw-text-k-gray-700">
                    <span> MR # </span>
                    <span>
                        <KHighlight :split="true" :textDecorator="'tw-text-k-true-blue'">
                            {{ item.mr }}</KHighlight>
                    </span>
                </small>
                <small class="tw-text-k-gray-700" v-if="item.is_discharged">
                    <span> - Discharged </span>
                </small>
                       <hr class="solid"/>
                 </div>
          </span>
                </li>
            </ul>
        </div>
        <select :name="collectionName" :id="collectionName" class="tw-hidden">
            <option v-for="(item,index) in itemsList" :key="index" :value="item.name" :selected="item.selected">
                {{ item.fullname ? item.fullname : item.name }}
            </option>
        </select>
        <label v-if="valid == false"
               class="tw-text-red-500 tw-absolute tw--bottom-4 tw-text-xs tw-left-0">{{ errorMsg }}</label>
    </div>
</template>

<script>
import {KHighlight, KIcon} from '@kipusystems/kipu-ui'

export default {
    name: "TWDropdown",
    directives: {
        offClick: {
            bind: function (el, binding, vnode) {
                el.clickOutsideEvent = function (event) {
                    if (!(el == event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
                document.body.addEventListener("touchstart", el.clickOutsideEvent);
            },
            unbind: function (el) {
                document.body.removeEventListener("click", el.clickOutsideEvent);
                document.body.removeEventListener("touchstart", el.clickOutsideEvent);
            },
            stopProp(event) {
                event.stopPropagation();
            }
        }
    },
    components: {KIcon, KHighlight},
    props: {
        label: {
            type: String,
            default: 'Patient name'
        },
        widthOptions: {
            type: String,
            validator: function (value) {
                return ['1/3', '2/3', '1/4', '2/4', '3/4', 'full'].indexOf(value) !== -1
            },
            default: "2/4"
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        valid: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isPatientList: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            required: false,
            default: () => [{name: 'State License', selected: false}, {
                name: 'Medicare PTAN',
                selected: false
            }, {name: 'Medicaid PIN', selected: false}]
        },
        errorMsg: {
            type: String,
            default: "Error message"
        },
        collectionName: {
            type: String,
            default: "patient_list"
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        showList: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            displayList: this.showList,
            itemsList: this.items,
            selectedItem: this.label
        }
    },
    watch: {
        items: {
            handler(newValue, oldValue) {
                this.itemsList = newValue
            }
        },
    },
    methods: {
        reset() {
            this.selectedItem = this.label
        },
        updateList(selectedItem) {
            this.closeList()
            this.itemsList = this.itemsList.map(el => {
              // nil guard and explicit check, since not all items might have 'is_enabled' property
              if (el.id == selectedItem.id && selectedItem?.is_enabled !== false) {
                return {...el, selected: true}
              } else {
                return {...el, selected: false}
              }
            });
            this.selectedItem = this.setSelectedItem()
            return this.itemsList
        },
        openList() {
            return this.disabled == false ? this.displayList = true : this.displayList = false
        },
        closeList() {
            return this.displayList = false
        },
        setSelectedItem() {
            let item = this.itemsList.find(el => el['selected'] == true)
            this.$emit('regarding-selected', item)
            return item ? item.fullname ? item.fullname : item.name : this.label
        },
        itemIsEnabled({is_enabled: isEnabled }) {
          if (isEnabled !== undefined) {
            // explicit check, since not all items might have 'is_enabled' property
            return isEnabled === true ? '' : 'tw-text-k-gray-700';
          } else {
            return '';
          }
        },
    },
    computed: {
        buttonDisabled() {
            return this.disabled == true ? 'tw-cursor-not-allowed tw-bg-k-gray-400 tw-text-k-gray-800' : ''
        },
        validInput() {
            return this.valid == true ? 'tw-border-k-gray-500' : 'tw-border-k-red-500'
        },
        labelColor() {
            return this.valid == true ? 'tw-text-k-gray-900' : 'tw-text-k-red-500'
        },
        baseContainerClass() {
            return ["tw-relative", "tw-group", "tw-flex", `tw-w-${this.widthOptions}`]
        },
        labelClass() {
            return ['tw-absolute',
                'tw-left-0',
                'tw-text-xs',
                'tw--top-2',
                'tw-bg-white',
                'tw-px-1',
                'group-focus-within:tw-text-k-purple',
                this.labelColor
            ]
        },
        buttonClass() {
            return ['tw-relative',
                'tw-w-full',
                'tw-bg-white',
                'tw-border',
                'tw-border-solid',
                'tw-rounded-md',
                'tw-border-gray-500',
                'tw-h-11',
                'tw-pl-3',
                'tw-pr-10',
                'tw-py-2',
                'tw-text-left',
                'tw-cursor-default',
                'focus:tw-outline-none',
                'focus:tw-border-k-purple',
                'sm:tw-text-sm']
        },
        ulClass() {
            return ['tw-absolute',
                'tw-list-none',
                'tw-z-10',
                'tw-mt-1',
                'tw-w-full',
                'tw-bg-white',
                'tw-shadow-lg',
                'tw-max-h-60',
                'tw-rounded-md',
                'tw-py-1',
                'tw-text-base',
                'tw-ring-1',
                'tw-ring-black',
                'tw-ring-opacity-5',
                'tw-overflow-auto',
                'focus:tw-outline-none',
                'sm:tw-text-sm'
            ]
        },
        liClass() {
            return ['tw-text-gray-900',
                'tw-cursor-default',
                'tw-select-none',
                'tw-relative',
                'tw-py-2',
                'tw-px-4',
                'tw-m-0',
                'hover:tw-bg-k-purple-500',
                'hover:tw-text-white',
            ]
        },
        caretClass() {
            return ['tw-absolute',
                'tw-inset-y-0',
                'tw-right-0',
                'tw-flex',
                'tw-items-center',
                'tw-pr-2',
                'tw-pointer-events-none',
            ]
        }
    }
}
</script>