import { render, staticRenderFns } from "./TWDropDown.vue?vue&type=template&id=16d0cfe4&"
import script from "./TWDropDown.vue?vue&type=script&lang=js&"
export * from "./TWDropDown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_@vue+compiler-sfc@3.3.4_css-loader@5.1.1_vue-template-compiler@2.7.14_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports