import { render, staticRenderFns } from "./ToastWrapper.vue?vue&type=template&id=8770c3b6&"
import script from "./ToastWrapper.vue?vue&type=script&lang=js&"
export * from "./ToastWrapper.vue?vue&type=script&lang=js&"
import style0 from "./ToastWrapper.vue?vue&type=style&index=0&id=8770c3b6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.1_@vue+compiler-sfc@3.3.4_css-loader@5.1.1_vue-template-compiler@2.7.14_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports