// this is a mounter
import Vue from 'vue'
import '../../../lib/vue_extensions/filters/truncate'
import '../../../lib/vue_extensions/directives/kipu-click-outside'
import ComposeMessageModal from "components/portal/messages/ComposeMessageModal";
import messagingStore from "store/messages";

function mount_compose_message_modal(){
    const el = document.getElementById('compose-message-modal')
    if(!el) return

    const data = Object.assign({}, el.dataset)
    const props = JSON.parse(data.props || "{}")
    messagingStore.state.authToken = props.authToken;
    const app = new Vue({
        store: messagingStore,
        render: h => h(ComposeMessageModal, { props })
    }).$mount()
    el.appendChild(app.$el)
}

packLoader(mount_compose_message_modal)
