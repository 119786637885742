<template>
  <div>
    <vue-snotify></vue-snotify>
<!--    {{ message }}-->
  </div>
</template>

<script>
  import { Subscriber } from "../../../lib/websockets/subscriber"
  import {SnotifyPosition} from "vue-snotify";
  import {filteringMixin} from "../../../mixins/event_rules/policies/filtering";

  export default {
    name: "ToasterWrapper",
    props:{
      currentUserId: {
        type: Number,
        required: true
      },
      locationId: {
        type: Number,
        default: null
      },
      websocketUrl: {
        type: String,
        required: true,
        default: 'ws://'
      },
      instanceId: {
        type: String,
        required: true
      },
      isLocationEnforced: {
        type: Boolean,
        required: true
      }
    },
    created() {
      if (this.websocketUrl) {
        this.websocket = new Subscriber({
          websocketUrl: this.websocketUrl,
          instanceId: this.instanceId,
          namespace: "EventRules::Notifications",
          topic: this.topic,
          dataHandler: this.dataHandler
        })
      }
    },
    destroyed() {
      this.closeWebsocket()
    },
    mixins: [filteringMixin],
    data() {
      return {
        websocket: null
      }
    },
    computed: {
      topic() {
        return `event_rules/notifications/${this.currentUserId}`
      }
    },
    methods: {
      dataHandler({ filter, data }){
        const { location_id: filteredLocationId, user_id: userId, bypass_location_scoping: bypassLocationScoping, topic } = filter

        if (topic === this.topic && userId === this.currentUserId && this.locationFilter(
            {
              filteredLocationId,
              bypassLocationScoping,
              locationId: this.locationId,
              isLocationEnforced: this.isLocationEnforced
            }
        )) {
          if (Object.keys(data).join(', ').includes('event_severity')) {
            const toast = this.$snotify[data.event_severity](data.event_message, data.event_title, {
              timeout: 5000,
              closeOnClick: false,
              pauseOnHover: true,
              position: SnotifyPosition.rightTop,
              showProgressBar: true,
              titleMaxLength: 30,
              buttons: [
                {text: 'Close', action: (toast) => this.$snotify.remove(toast.id), bold: false},
              ],
            });
            toast.on('click', (toast) => {
              this.redirectToInbox(data.notification_id);
            });
          }
        }
      },
      redirectToInbox(id){
        window.onbeforeunload = null;
        window.location = `/user_notifications/inbox/view?notification_id=${id}`
      },
      closeWebsocket() {
        try {
          if (this.websocket) this.websocket.close()
        } catch(err) {
          console.error(err)
        }
      },
    }
  }
</script>

<style>
  .snotifyToast__inner {
    min-height: 60px !important;
    font-size: 12px !important;
  }
  .snotifyToast__title {
    font-size: 1.3em !important;
    font-weight: 700 !important;
  }
  .snotifyToast__body {
    font-size: 1em !important;
  }
  .snotify {
    width: 25rem !important;
  }
  .snotify-info {
    background-color: #5bc0de !important;
  }
  .snotify-warning {
    background-color: #e99002 !important;
  }
  .snotify-error {
    background-color: #f04124 !important;
  }
</style>