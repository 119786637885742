<template>
    <div>
        <div class="tw-flex tw-mr-6" v-if="isFaceSheetOrigin">
            <button type="button"
                    :disabled="!isInitiatorEligibleToCompose"
                    data-skip-legacy="true"
                    class="tw-inline-flex tw-justify-center tw-px-4 tw-border-2 tw-border-transparent tw-font-semibold tw-rounded-full tw-uppercase hover:tw-cursor-pointer tw-text-sm tw-py-2 tw-text-white tw-bg-k-dark-blue active:tw-bg-k-dark-blue hover:tw-bg-k-true-blue focus:tw-outline-none focus:tw-bg-k-true-blue focus:tw-ring-2 focus:tw-ring-k-dark-blue disabled:tw-bg-k-gray-300 disabled:tw-text-k-gray-700 tw-shadow-md"
                    mode="primary"
                    @click="onNewMessageButtonClicked"
                    data-qa-id="emr_messaging_new_message_button">
                New Message
            </button>
        </div>
        <div v-show="showDialog || isModalVisible" class="tw-fixed tw-inset-0"
             aria-labelledby="modal-title" role="dialog" aria-modal="true"
             v-bind:style="{ 'z-index': zIndex, 'paddingTop': modalPaddingTopStyle}">
            <div :class="composeMessageContainer">
                <div class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"
                     aria-hidden="true"></div>
                <div class="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg  tw-px-12 tw-pt-8 tw-pb-8 tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all">
                    <h3 data-qa-id="messaging_new_message_header">New Message</h3>
                    <p v-if="isPortalOrigin" class="tw-text-red-500" data-qa-id="messaging_emergency_warning_text">{{this.emergencyWarningText}}</p>
                    <!--Content-->
                    <div class="tw-grid tw-space-y-10 tw-mt-4">
                        <!--EMR-->
                        <div class="tw-grid tw-gap-10">
                            <div
                                v-if="isFaceSheetOrigin && showMessageTypeSelection && patientHasPortalUsers"
                                class="tw-mt-6"
                            >
                                <div class="tw-absolute tw-text-xs tw--mt-5 tw-pl-2 tw-bg-white tw-px-1 group-focus-within:tw-text-k-purple tw-text-k-gray-900">
                                    Type <span class="tw-text-red-600"> * </span>
                                </div>
                                <PRadioButtonGroup
                                    ref="emrMessagingRadioGroup"
                                    class="tw-justify-start tw-ml-4"
                                    @radio-group-selection-changed="onRadioSelectionChange"
                                    :valid="!this.v$.messageType.$error"
                                    error-msg="Message Type is required"
                                    data-qa-id="emr_messaging_messagetype_radio_button_group"/>
                            </div>

                            <!--Regarding-Preselected Label-->
                            <div v-if="isFaceSheetOrigin" class="tw-mt-8">
                                <p class="tw-absolute tw-text-xs tw--mt-5 tw-pl-2 tw-bg-white tw-px-1 group-focus-within:tw-text-k-purple tw-text-k-gray-900">
                                    Regarding <span class="tw-text-red-600"> * </span>
                                </p>
                                <p :class="'tw-ml-3'" data-qa-id="emr_messaging_selected_regarding_label">{{ this.regardsList[0].name }}</p>
                            </div>

                            <!--Recipients-->
                            <div v-if="isFaceSheetOrigin || isMessagingCenterOrigin">
                                <PMultiSelectSearch
                                    ref="multiselect_list"
                                    :searchable-list="this.$store.state.messageRecipientsList"
                                    :max-selection="this.maxSelection"
                                    :is-selection-disabled="this.isRecipientSelectionDisabled"
                                    :error-msg="'Recipient is required'"
                                    :label="'Recipient(s)'" :is-required="true"
                                    data-qa-id="emr_messaging_recipients_selection_list"/>
                            </div>
                        </div>

                        <!--PORTAL-->
                        <div v-if="isPortalOrigin" class="tw-grid tw-space-y-10">
                            <!--REGARDING PRESELECT-->
                            <div v-if="regardsList.length === 1">
                                <p :class="'tw-bg-white tw-text-xs tw-text-k-gray-900'">Regarding</p>
                                <p :class="'tw-ml-3'" data-qa-id="emr_messaging_selected_regarding_label">{{ this.regardsList[0].fullname }}</p>
                            </div>
                            <!--REGARDING-->
                            <div v-if="regardsList.length > 1">
                                <TWDropdown :items="regardsList" label="Regarding" :is-required="true"
                                            ref="regarding_dropdown"
                                            :show-label="true"
                                            @regarding-selected=" (selectedItem) => onRegardingDropDownSelected(selectedItem)"
                                            :width-options="'full'"
                                            error-msg="Regarding selection is required"
                                            :valid="!this.v$.selectedReference.$error"
                                            data-qa-id="portal_messaging_regarding_dropdown"></TWDropdown>
                            </div>
                            <div v-if="this.$store.state.showRecipients && recipientsList.length === 1">
                                <p :class="'tw-bg-white tw-text-xs tw-text-k-gray-900'">Recipient</p>
                                <p :class="'tw-ml-3'" data-qa-id="portal_messaging_selected_recipient">{{ this.recipientsList[0].name.full }}</p>
                            </div>
                            <!--RECIPIENTS-->
                            <div v-if="this.$store.state.showRecipients && recipientsList.length != 1">
                                <TWDropdown :items="recipientsList" :disabled="this.recipientsList.length == 0"
                                            label="Recipient" :is-required="true"
                                            @regarding-selected=" (selectedItem) => onDropDownSelected(selectedItem)"
                                            :width-options="'full'"
                                            error-msg="Recipient selection is required"
                                            :valid="!this.v$.selectedRecipient.$error"
                                            ref="recipient_dropdown"
                                            data-qa-id="portal_messaging_recipient_dropdown"></TWDropdown>
                            </div>
                        </div>
                        <!--SUBJECT-->
                        <div class="tw-relative tw-group tw-flex" showlabel="true"
                             valid="true">
                            <p class="tw-absolute tw-left-2 tw-text-xs tw--top-2 tw-bg-white tw-px-1 group-focus-within:tw-text-k-purple tw-text-k-gray-900">
                                Subject</p>
                            <input placeholder="Enter your subject here" v-model="subject" maxlength="65" data-qa-id="messaging_subject_input"
                                   :class="'tw-rounded tw-h-10 tw-border tw-border-solid tw-px-2 tw-py-0 tw-text-base tw-leading-4 tw-w-full focus:tw-outline-none focus:tw-border-2 focus:tw-border-k-purple-500 hover:tw-shadow tw-border-k-gray-500'">
                            <label class="tw-text-gray-500 tw-absolute tw--bottom-4 tw-text-xs tw-right-0 tw-text-right">
                                <span>{{ subject ? subject.length : 0 }}</span>/65
                            </label>
                        </div>
                        <!--MESSAGE-->
                        <div class="tw-relative tw-group tw-flex" showlabel="true" valid="true">
                            <p class="tw-absolute tw-left-2 tw-text-xs tw--top-2 tw-bg-white tw-rounded tw-px-px tw-py-px group-focus-within:tw-text-k-purple tw-text-k-gray-900">
                                Message <span class="tw-text-red-600"> * </span>
                            </p>
                            <textarea placeholder="Enter your message here" v-model="v$.message.$model"
                                      data-qa-id="messaging_message_input_textarea"
                                      ref="message_input_textarea"
                                      @input="autoGrowHeight($event)"
                                      :style="`${isLargeScreen ? 'min-height : 6rem !important ; max-height : 21rem !important;' : 'min-height : 6rem !important ; max-height : 13rem !important;' }`"
                                      :class="['tw-border tw-border-solid tw-text-base tw-w-full tw-rounded focus:tw-outline-none focus:tw-border-k-purple-500 tw-pl-2 tw-pt-4', validMessageInput]">
                            </textarea>
                            <label v-if="this.v$.message.$error" data-qa-id="messaging_message_validation_error_label"
                                   class="tw-text-red-500 tw-absolute tw--bottom-4 tw-text-xs tw-left-0">
                                Message is required
                            </label>
                        </div>
                    </div>
                    <!--Footer-->
                    <div :class="`${isLargeScreen ? 'tw-flex tw-flex-row tw-space-x-11 tw-mt-4' : 'tw-flex tw-flex-col-reverse tw-space-x-11 tw-mt-4'}`">
                        <div>
                            <p class="tw-italic" data-qa-id="messaging_disclaimer_label">Messaging is intended for non-urgent matters. Message
                                response times
                                may vary.</p>
                        </div>
                        <div class="tw-flex tw-flex-row tw-space-x-4">
                            <span class="tw-w-full"></span>
                            <button type="button" data-skip-legacy="true"
                                    @click="onCancel"
                                    mode="additional"
                                    data-qa-id="messaging_compose_cancel_button"
                                    class="tw-inline-flex tw-justify-center tw-px-4 tw-h-10 tw-border-2 tw-border-transparent tw-font-semibold tw-rounded-full tw-uppercase hover:tw-cursor-pointer tw-text-sm tw-py-2 tw-text-k-dark-blue tw-bg-white tw-no-border tw-no-shadow hover:tw-bg-k-gray-300 focus:tw-border-k-dark-blue focus:tw-bg-white disabled:tw-bg-white disabled:tw-text-k-gray-300"
                            >Cancel
                            </button>
                            <button type="button" data-skip-legacy="true"
                                    @click="onSend"
                                    data-qa-id="messaging_compose_send_button"
                                    class="tw-inline-flex tw-justify-center tw-px-4 tw-h-10 tw-border-2 tw-border-transparent tw-font-semibold tw-rounded-full tw-uppercase hover:tw-cursor-pointer tw-text-sm tw-py-2 tw-text-white tw-bg-k-dark-blue active:tw-bg-k-dark-blue hover:tw-bg-k-true-blue focus:tw-outline-none focus:tw-bg-k-true-blue focus:tw-ring-2 focus:tw-ring-k-dark-blue disabled:tw-bg-k-gray-300 disabled:tw-text-k-gray-700 tw-shadow-md"
                                    mode="primary">Send
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import PRadioButtonGroup from "components/portal/messages/PRadioButtonGroup";
import {KAutoComplete, KPatientAutoComplete} from "@kipusystems/kipu-ui";
import PChipList from "components/portal/messages/PChipList";
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import TWDropdown from "components/shared/TWDropDown";
import PAutoComplete from "components/portal/messages/PAutoComplete";
import PMultiSelectSearch from "components/portal/messages/PMultiSelectSearch";

const MESSAGE_TYPE = {
    STAFF: "staff",
    PORTAL: "portal"
}

export default {
    name: 'ComposeMessageModal',
    components: {
        PMultiSelectSearch,
        PAutoComplete, TWDropdown, PChipList, PRadioButtonGroup, KPatientAutoComplete, KAutoComplete
    },
    setup() {
        return {v$: useVuelidate()}
    },
    props: {
        emergencyWarning: "",
        paddingTop: {},
        showDialog: {},
        zIndex: {},
        patientName: "",
        recipients: {
            type: Array,
            required: false,
            default: () => []
        },
        regardsList: {
            type: Array,
            required: false,
            default: () => []
        },
        recipientsList: {
            type: Array,
            required: false,
            default: () => []
        },
        careteamMembers: [],
        isMessagingCenterOrigin: {
            type: Boolean,
            required: false,
            default: false
        },
        isFaceSheetOrigin: {
            type: Boolean,
            required: false,
            default: false
        },
        showMessageTypeSelection: {
            type: Boolean,
            required: true,
        },
        isInitiatorEligibleToCompose: {
          type: Boolean,
          required: true
        },
        patientHasPortalUsers: {
          type: Boolean,
          required: false,
          default: false
        },
        isPortalOrigin: {
            type: Boolean,
            required: false,
            default: false
        },
        isMessageTypeSelected: false,
    },
    data() {
        return {
            radioGroupList: [{name: "Portal", selected: false}, {name: "Staff", selected: false}],
            messageType: null,
            selectedReference: null,
            selectedRecipient: null,
            selectedRegardingList: [],
            selectedRecipientList: [],
            selectableRecipients: [],
            subject: "",
            message: "",
            isModalVisible: this.showDialog,
            emrHeader: document.getElementById("header"),
            isLargeScreen: true
        }
    },
    validations() {
        return {
            selectedReference: {required},
            selectedRecipient: {required},
            selectedRegardingList: {required},
            selectedRecipientList: {required},
            message: {required},
            messageType: {required}
        }
    },
    mounted() {
        window.addEventListener('resize', this.setLargeScreen);
    },
    updated() {
        window.addEventListener('resize', this.setLargeScreen);
    },
    unmounted() {
        window.removeEventListener('resize', this.setLargeScreen);
    },
    computed: {
        emergencyWarningText() {
          return this.emergencyWarning
        },
        validMessageInput() {
            return this.v$.message.$error ? 'tw-border-k-red-500' : 'tw-border-k-gray-500'
        },
        maxSelection(){
            return this.isFaceSheetOrigin && this.messageType === 'portal' ? 1 : 99999
        },
        isRecipientSelectionDisabled(){
            const {
                isFaceSheetOrigin,isMessagingCenterOrigin, isPortalOrigin,
                maxSelection, messageType
            } = this

            if (isFaceSheetOrigin && messageType && this.$refs.multiselect_list) {
                return (this.$refs.multiselect_list.selectedList && this.$refs.multiselect_list.selectedList.length >= maxSelection)
            } else if (isMessagingCenterOrigin || isPortalOrigin) {
                return false
            } else {
                return true
            }
        },
        composeMessageContainer(){
            return this.isLargeScreen? 'tw-flex tw-items-end tw-justify-center tw-h-4/6 tw-pt-4 tw-px-4 tw-pb-20 tw-text-center' : 'tw-flex tw-items-center tw-justify-center tw-h-full tw-px-4 tw-pb-10 tw-text-center'
        },
        modalPaddingTopStyle(){
            return this.isLargeScreen ?  '600px !important' : ''
        }

    },
    watch: {
        recipientsList(newValue) {
            if (newValue.length === 1)
                this.selectedRecipient = newValue[0]
        },
        // Tobe triggered only from face sheet when the message type is defaulted to staff only.
        isModalVisible(newValue) {
            if (this.isFaceSheetOrigin) {
                if (newValue === true) {

                    this.getMessageRecipientsList()
                }

                this.onPopUpDisplayedOrClosed(newValue)
            }
        },
        showDialog(newValue) {
            this.onPopUpDisplayedOrClosed(newValue)
        },
    },
    methods: {
        setLargeScreen() {
            this.isLargeScreen = (this.$vuetify && this.$vuetify.breakpoint && this.$vuetify.breakpoint.lgAndUp) || document.documentElement.clientWidth > 1264;
        },
        onPopUpDisplayedOrClosed(isDisplayed) {
            if (isDisplayed === true) {
                this.setLargeScreen()
                if (this.regardsList.length == 1) {
                    this.selectedReference = this.regardsList[0]
                }

                if (this.$refs.emrMessagingRadioGroup) {
                    this.$refs.emrMessagingRadioGroup.groupList = this.radioGroupList
                }

                this.messageType = this.getDefaultMessageType()
            } else {
                if (this.$refs.emrMessagingRadioGroup) {
                    this.$refs.emrMessagingRadioGroup.groupList = []
                }
            }
        },
        validate() {
            this.v$.message.$touch()
            if (this.isPortalOrigin) {
                if (this.regardsList.length > 1) {
                    this.v$.selectedReference.$touch()
                }
                if (this.recipientsList.length > 1) { //We preselect the recipient if there is only one, so no need of validating it.
                    this.v$.selectedRecipient.$touch()
                }
            } else {
                this.$refs.multiselect_list.v$.selectedList.$touch()

                if (this.isFaceSheetOrigin && this.patientHasPortalUsers) {
                    this.v$.messageType.$touch()
                }
            }
        },
        autoGrowHeight() {
            if (event)
                event.target.style.height =  event.target.scrollHeight+"px";
        },
        onSend() {
            this.validate()
            let participantsList = null

            if ((this.isMessagingCenterOrigin || this.isFaceSheetOrigin) && this.$refs.multiselect_list && this.$refs.multiselect_list.selectedList && this.$refs.multiselect_list.selectedList.length > 0) {
                participantsList = []
                this.$refs.multiselect_list.selectedList.map(el => {
                    participantsList.push({id: el.id, type: el.type})
                })
            }

            const messageData = {
                ...(this.selectedReference && this.selectedReference.id) && {reference_id: this.selectedReference.id},
                ...(this.selectedRecipient && this.selectedRecipient.id) && {
                    participants: [{
                        id: this.selectedRecipient.id,
                        type: "User"
                    }]
                },
                ...(participantsList && participantsList.length > 0) && {participants: participantsList},
                subject: this.subject,
                message_content: this.message,
                message_type: this.messageType
            }

            if (this.v$.$errors.length === 0) {
                this.$store.state.messageData = messageData
                if (this.isFaceSheetOrigin) {
                    this.isModalVisible = false
                    this.$store.dispatch("composeMessage");
                } else {
                    this.$emit('on-send', messageData)
                }
                this.reset()
                if (!this.isPortalOrigin)
                    this.emrHeader.style.zIndex = '11'
            }
        },
        onCancel() {
            this.reset()
            this.$emit('on-cancel')
            this.isModalVisible = false
            if (!this.isPortalOrigin)
                this.emrHeader.style.zIndex = '11'
        },
        onNewMessageButtonClicked() {
            this.isModalVisible = true
            if (!this.isPortalOrigin)
            {
                this.emrHeader.style.zIndex = '0'
            }
        },
        reset() {
            this.selectedReference = null
            this.selectedRecipient = null
            this.subject = null
            this.message = null
            this.messageType = null
            if (this.isMessagingCenterOrigin || this.isFaceSheetOrigin) {
                this.$refs.multiselect_list.reset()
            }
            if (this.isPortalOrigin) {
                if (this.$refs.regarding_dropdown) {
                    this.$refs.regarding_dropdown.reset()
                }
                this.resetRecipientDropDown();
            }

            if (this.isFaceSheetOrigin && this.patientHasPortalUsers) {
                this.radioGroupList.forEach(el => {el.selected = false})
            }

            if (this.v$) this.v$.$reset();
        },
        onRegardingDropDownSelected(item) {
            this.selectedReference = item
            this.resetRecipientDropDown();
            this.$emit('reference-dropdown-selected', item)
        },
        resetRecipientDropDown() {
            this.selectedRecipient = null

            if (this.$refs.recipient_dropdown) {
                this.$refs.recipient_dropdown.reset();
            }
        },
        onDropDownSelected(item) {
            this.selectedRecipient = item
        },
        onRemoveChip(item, list) {
            const index = list.indexOf(item);
            if (index > -1) {
                list.splice(index, 1);
            }
        },
        onRecipientRemoveChip(item) {
            const index = this.selectedRecipientList.indexOf(item);
            if (index > -1) {
                this.selectedRecipientList.splice(index, 1);
            }
        },
        onRadioSelectionChange(selectedItem) {
            this.messageType = selectedItem.name.toLowerCase()

            this.getMessageRecipientsList()
            this.$refs.multiselect_list.reset()
        },
        getMessageRecipientsList(){
            const params = { messageType: this.messageType }

            if (!this.isMessagingCenterOrigin && !!this.regardsList[0]) {
                params.referenceId   = this.regardsList[0].id
                params.referenceType = "Patient"
            }
            this.$store.dispatch("getMessageRecipientsList", params);
        },
        getDefaultMessageType() {
            let defaultMessageType = null

            const {
                isMessagingCenterOrigin, isFaceSheetOrigin,
                patientHasPortalUsers, isPortalOrigin, showMessageTypeSelection
            } = this

            if (isMessagingCenterOrigin
                || (isFaceSheetOrigin && !(showMessageTypeSelection && patientHasPortalUsers))) {
                defaultMessageType =  MESSAGE_TYPE.STAFF
            }

            if (isPortalOrigin) {
                defaultMessageType = MESSAGE_TYPE.PORTAL
            }

            if (defaultMessageType && defaultMessageType ==  MESSAGE_TYPE.STAFF) {
                this.getMessageRecipientsList()
            }

            return defaultMessageType
        },
    }
}
</script>