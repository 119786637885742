// this is a mounter
import Vue from 'vue'
import MessageEnvelopeWrapper from '@/components/messaging/MessageEnvelopeWrapper'
import '@/lib/vue_extensions/filters/truncate'
import '@/lib/vue_extensions/directives/kipu-click-outside'



function mount_message_envelope(){
    const el = document.getElementById('messaging-message-envelope')

    if(!el) return

    const data = Object.assign({}, el.dataset)
    const props = JSON.parse(data.props || "{}")

    const app = new Vue({
        render: h => h(MessageEnvelopeWrapper, { props })
    }).$mount()

    el.appendChild(app.$el)
}

packLoader(mount_message_envelope)
