import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const state = {
  hasAlert: false,
  alertMessage: "",
  alertStatus: "success",
}

const mutations = {
  SET_NOTIFICATION(state, notification) {
    state.hasAlert = true
    state.alertMessage = notification.message
    state.alertStatus = notification.status
  }
}

const actions = {
  showNotification(context, notification) {
    context.commit('SET_NOTIFICATION', notification)
  }
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
});
