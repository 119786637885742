<template>
  <div class="list-item-group"
       :class="className(this.category)"
       v-if="this.notifications.length > 0">
    <div class="tw-flex tw-px-1 tw-py-5">
      <small class="tw-text-gray-700 tw-not-italic tw-text-sm tw-font-bold tw-flex-none tw-h-1">{{ this.category }}</small>
      <div class="spacer tw-flex-grow"></div>
      <button class="tw-flex-none tw-bg-transparent hover:bg-transparent border-transparent hover:border-transparent tw-mr-3 tw-py-0 tw-w-0 tw-h-0"
              @click="markNotificationsAsRead()">
        <span class="glyphicon glyphicon-remove-sign" />
      </button>
    </div>
    <NotificationCard
      :items="notifications"
      :authToken="authToken"
      :locale="locale"
    />
  </div>
</template>

<script>
  import axios from '~shared/Axios'
  import NotificationCard from './NotificationCard'

  export default {
    name: "NotificationListGroup",
    props: {
      notifications: {
        type: Array,
        default() {
          return []
        }
      },
      authToken: {
        type: String,
        default: ''
      },
      category: {
        type: String,
        default: ''
      },
      isLegacy: {
        type: Boolean,
        default: false
      },
      locale: {
        type: String,
        required: true
      }
    },
    components: {
      NotificationCard
    },
    methods: {
      async markNotificationsAsRead(){
        try {
          const url = this.isLegacy === true ? "/event_rules/notifications/legacy_read" : "/event_rules/notifications/read"
          const ids = this.notifications.map(x => x.id);
          await axios.patch(url, {
            ids: ids
          }, {
            headers: {
              'Content-Type': 'application/json',
              "X-CSRF-TOKEN": this.authToken
            }
          })
        } catch (error) {
          console.log(error)
        }
      },
      className(category) {
        return `${category.toLowerCase()}-notifications`;
      }
    }
  }
</script>

<style scoped>
  button, button:hover {
    background-color: transparent;
    border: solid 1px transparent;
  }
  button:focus{
    outline:none !important;
  }
</style>
