<template>
  <div :class="tabsDivClass"
       v-bind:style="tabsDivStyle"
  >
    <ul :class="tabsUnorderedListClass"
        v-bind:style="tabsUnorderedListStyle"
    >
      <li :class="tabStyling(tabsListClass, index)"
          v-bind:style="tabsListStyle"
          v-for="(tab, index) in tabs"
          :key="tab.title"
          @click="selectTab(index)"
      >
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      tabsListClass: {
        type: String,
        default: ''
      },
      tabsListStyle: {
        type: Object,
        default() {
          return {}
        }
      },
      tabsDivClass: {
        type: String,
        default: ''
      },
      tabsDivStyle: {
        type: Object,
        default() {
          return {}
        }
      },
      tabsUnorderedListClass: {
        type: String,
        default: ''
      },
      tabsUnorderedListStyle: {
        type: Object,
        default() {
          return {}
        }
      },
    },
    data () {
      return {
        selectedIndex: 0,
        activeTabIndex: 0,
        tabs: []
      }
    },
    created () {
      this.tabs = this.$children
    },
    mounted () {
      this.selectTab(0)
    },
    methods: {
      selectTab(i) {
        this.selectedIndex = i

        this.tabs.forEach((tab, index) => {
          tab.isActive = (index === i);
          if (index === i) {
            this.activeTabIndex = index;
          }
        })
      },
      tabStyling(tabsListClass, index){
        if (this.activeTabIndex === index) {
          return `active ${tabsListClass}`;
        } else {
          return `${tabsListClass} inactive`;
        }
      }
    }
  }
</script>

<style scoped>
  li {
    position: relative;
    cursor: pointer;
    background-color: white;
    z-index: 20;
  }
  .active {
    --text-opacity: 1 !important;
    box-shadow: 0 5px 0 rgba(59, 130, 246, var(--text-opacity));
  }
  .inactive {
    --text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--text-opacity)) !important;
  }
</style>
