<!-- This component renders a tooltip with an icon and a hovering text.
     The default state of this component is 'info' which shows a gray info circle icon.

     This component can be customized to either 'warning' state or 'error' state,
     which will show a yellow warning icon or a red error icon respectively by passing the 'type' prop.

     If this tooltip is intended for other than these three states, then
     pass the 'type' as 'info' and the value for 'infoText' and you may customize these properties 'icon, iconSize, iconColor, direction'

     Additionally if you want to replace the standard info icon with a custom item or with any other compatible element
     you may pass as a template for the toolTipPlaceholder slot-->

<template>
  <div class="infoTooltip">
    <p v-if="infoText" class="tw-cursor-pointer">{{infoText}}</p>
    <i :class="infoIconStyleClass" v-if="isIconVisible"></i>
    <slot v-else name="toolTipPlaceholder"></slot>
    <span v-if="tooltipLabel && tooltipLabel.length>0">
    <p v-if="direction != 'right'" :class="infoToolTipTextClass">{{ tooltipLabel }}</p>
    <p v-else class="infoToolTipText">{{ tooltipLabel }}</p>
    </span>
  </div>
</template>
<script>
export default {
  name: 'InfoToolTip',
  props: {
    infoText: {
      default: '',
      type: String,
    },
    isIconVisible: {
      default: true,
      type: Boolean,
    },
    tooltipLabel: {
      default: '',
      type: String,
    },
    direction: {
      default: 'right',
      type: String,
    },
    iconColor: {
      default: 'k-gray-700',
      type: String,
    },
    iconSize: {
      default: '2xl',
      type: String,
    },
    icon: {
      default: 'mdi-information-outline',
      type: String,
    },
    type: {
      default: 'info',
      validator(value) {
        return ['info', 'warning', 'error'].includes(value)
      },
      type: String,
    },
  },
  computed: {
    infoToolTipTextClass() {
      return this.direction != 'right' ? `infoToolTipText direction-${this.direction}` : 'infoToolTipText'
    },
    infoIconStyleClass() {
      if (this.type === 'info') {
        return `mdi ${this.icon} infoIcon tw-text-${this.iconSize} tw-text-${this.iconColor}`
      } else {
        return `mdi ${this.infoIcon} infoIcon tw-text-${this.iconSize} tw-text-${this.infoIconColor}`
      }
    },
    infoIcon() {
      switch (this.type) {
        case 'info':
          return 'mdi-information-outline'
        case 'warning':
          return 'mdi-alert-circle-outline'
        case 'error':
          return 'mdi-alert'
        default:
          return 'mdi-information-outline'
      }
    },
    infoIconColor() {
      switch (this.type) {
        case 'info':
          return 'k-gray-700'
        case 'warning':
          return 'k-yellow-500'
        case 'error':
          return 'k-red-500'
        default:
          return 'k-gray-700'
      }
    },
  }
}
</script>
<style>
.infoIcon {
  color: grey;
}

.infoTooltip {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
  height: 100%;
}

.infoTooltip .infoToolTipText {
  visibility: hidden;
  max-width: 350px;
  min-width: 150px;
  background-color: grey;
  color: #fff;
  font-size: 14px;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  margin-left: 20px;

  position: absolute;
  z-index: 1;
  white-space: pre-line;
}

.infoTooltip .infoToolTipText.direction-top {
  bottom: 0;
  transform: translate(-50%, 0);
}

.infoTooltip .infoToolTipText.direction-left {
  right: 0;
  transform: translate(0, -50%);
}

.infoTooltip:hover .infoToolTipText {
  visibility: visible
}

.infoTooltip .infoToolTipText.direction-top-left {
  bottom: 0;
  transform: translate(-50%, 0);
}

.infoTooltip .infoToolTipText.direction-top-right {
  bottom: 0;
  transform: translate(0, -50%);
}

.infoTooltip .infoToolTipText.direction-left-top {
  right: 0;
  transform: translate(-50%, 0);
}

.infoTooltip .infoToolTipText.direction-left-bottom {
  right: 0;
  transform: translate(-50%, -50%);
}

.infoTooltip .infoToolTipText.direction-right-top {
  right: 0;
  transform: translate(50%, 0);
}

.infoTooltip .infoToolTipText.direction-right-bottom {
  right: 0;
  transform: translate(100%, 0);
}

</style>